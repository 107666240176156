// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getDatabase, connectDatabaseEmulator, ref, onValue } from "firebase/database";

//NODE_OPTIONS=--openssl-legacy-provider CHOKIDAR_USEPOLLING=true 
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCZo5SA-Inztc6hthR8g-VkDVe9nxopynU",
    authDomain: "lethalskins.firebaseapp.com",
    projectId: "lethalskins",
    storageBucket: "lethalskins.appspot.com",
    messagingSenderId: "941217959533",
    appId: "1:941217959533:web:3907a93583c3d93372b964",
    measurementId: "G-M87ESF0JL6"
  };
  
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const db = getDatabase();
if (window.location.hostname === "localhost") {
    console.log("using db emulator")
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(db, "127.0.0.1", 9000);
} 
export const database = db;


const storageInit = getStorage();
if (window.location.hostname === "localhost") {
    console.log("using storage emulator")
    // Point to the Storage emulator running on localhost.
    connectStorageEmulator(storageInit, "127.0.0.1", 9199);
  } 
export const storage = storageInit;



export const GOOGLE_CLIENT_ID =
    'YOUR_OAUTH_CLIENT_ID';


export const getUserData = (userId) => {
    return new Promise((resolve,reject) => {
        const dataRef = ref(db, '/users/' + userId);
        onValue(dataRef, (snapshot) => {
            resolve(snapshot.val())
        })
    });
}


    
