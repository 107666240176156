import { Paintbrush, RefreshCcwDot, Stars } from "lucide-react";
import { colors } from "../utils/colors"
import { useState, useEffect } from "react";
import { styled } from "@mui/material";
export default function FeatureCarousel(props){
    const contents = [
        {
            icon: RefreshCcwDot,
            title: "Sync",
            content: "Seamlessly Sync Your Skins with Your Entire Lobby, Automatically",
            background: colors.theme[0]
        },
        {
            icon: Paintbrush,
            title: "Create",
            content: "Design Skins with the Skin Editor, with Real-Time 3D Preview",
            background: colors.theme[1]
        },
        {
            icon: Stars,
            title: "Discover",
            content: "Share Your Creations and Discover Skins Created By The Community",
            background: colors.theme[2]
        },
    ]; // Your contents
    const backgrounds = [colors.theme[0], colors.theme[1], colors.theme[2]]; // Background colors
    const [index, setIndex] = useState(0);

    const classes = {
        container: {
            backgroundColor: contents[index].background,
            height: "inherit",
            width: "100%",
            borderRadius: "inherit",
            transition: "background-color 1s ease, transform 1s ease",
            // transform: "",
            display: "flex",
            justifyContent: "center",

            alignItems: "center",
            flexDirection: "column",
            // borderRadius: 4,
            padding: "1em",
            gap: "1em",
            color: colors.theme[3]
        },
        headerContainer:{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5em",
            // backgroundColor: colors.theme[3],
            // color: contents[index].background,
            // padding: "0.5em",
            // width: "50%",
            borderRadius: 4
        },
        iconContainer: {
            height: 45,
            width: 45,
            backgroundColor: props.color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4
        },
        bodyText: {
            // padding: "1em 0",
            fontSize: "20pt",
            fontWeight: "bold",
            textAlign: "center",
        }
    }
    const Title = styled('h3')({
        color: props.color,
        fontSize: "26pt",
        fontWeight: "bold",
        lineHeight: "26px",
        padding: 0,
        margin: 0
    })


    useEffect(() => {
        const interval = setInterval(() => {
          setIndex((prevIndex) => (prevIndex + 1) % contents.length);
        }, 5000);
    
        return () => clearInterval(interval);
      }, []);


    const IconComponent = contents[index].icon;
    return (
        <div style={classes.container}>
            <div style={classes.headerContainer}>
                <span style={classes.iconContainer}><IconComponent size={35}/></span>
                <Title style={classes.title}>{contents[index].title}</Title>
            </div>
            <div style={classes.bodyText}>
                {contents[index].content}
            </div>
        </div>
    )
}