import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { analytics } from '../firebaseConfig';
import { logEvent } from "firebase/analytics";

import { getAuth, connectAuthEmulator, signInWithCustomToken } from "firebase/auth";
import { colors } from '../utils/colors.js';
import SpaceBackground from '../components/SpaceBackground';
import FeatureCarousel from '../components/FeatureCarousel.jsx';
import { CircularProgress } from '@mui/material';
import { SteamLogo } from '../utils/svgLib.js';
import { useLSAuth } from '../providers/AuthContext.jsx';
import { ApiUtil } from '../utils/apiUtil.js';

const auth = getAuth();

if (window.location.hostname === "localhost") {
  console.log("using auth emulator")
  connectAuthEmulator(auth, "http://localhost:9099");
}

function Login(props) {
  const {login} = useLSAuth();
  const api = new ApiUtil();

  const defaultSpaceSpeed = 0.5;
  const [spaceSpeed, setSpaceSpeed] = useState(defaultSpaceSpeed);

  const navigate = useNavigate();

  const doLoginEffect = (nav) => {
    document.getElementById('content').style.opacity = 0;
    setSpaceSpeed(10);
    setTimeout(() => {
      navigate(nav)
    }, 1500);
  }

  useEffect(()=>{
    console.log("useeffect doSteamAuth")
    const loginParams = window.location.search;
    api.doSteamAuth(loginParams).then((response)=>{
      console.log(response)
      signInWithCustomToken(auth, response.token)
        .then((userCredential) => {
          login(userCredential.user).then(()=>{
            console.log("doSteamAuth authprovider login complete")
            const user = userCredential.user;
          
            console.log("doSteamAuth signed in as",user)
            // getAPIInstance(userCredential);
            logEvent(analytics, response.newUser ? 'user_signup' : 'user_login');
            doLoginEffect("/dashboard");
          })
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });
    }).catch((err)=>{
      console.error(err)
    });
  }, [])


  const classes = {
    container: {
      // backgroundColor: colors.theme[4],
      height: "100vh",
      width: "100%",
    },
    flexContainer: {
      height: "90%",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    contentContainer: {
      display: "flex",
      width: "50%",
      margin: "0 auto",
      height: "40em",
      borderRadius: 4,
      transition: "opacity 0.75s"
    },
    contentLeft: {
      display: "flex",
      width: "50%",
      backgroundColor: colors.descript["offWhite"],
      borderRadius: "inherit",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    contentRight: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // padding: "1em 0px",
      // paddingBottom: "0em",
      width: "50%",
      backgroundColor: colors.theme[3],
      borderRadius: "inherit",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      // gap: 10
    },
    linkContainer: {
      color: colors.descript["offWhite"],
      marginTop: "1em",
      textAlign: "center",
      width: "100%",
      display: "block"
    }

  }

  return (
    <>
      <div style={classes.container}>
        <div style={classes.flexContainer}>
          <div style={classes.contentContainer} id="content">
            <div style={classes.contentLeft}>
              <FeatureCarousel />
            </div>
            <div style={classes.contentRight}>
              <div style={{color: "white", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                  <SteamLogo size={75} color="white"/>
                </div>
                
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}}>
                  <CircularProgress color="inherit" size={25}/>
                  <h3>Logging in with Steam</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SpaceBackground speed={spaceSpeed} />
    </>
  );

}

Login.defaultProps = {
  formType: "login"
}

export default Login;