export const VerticalLine = (props) => {
    
    var lineStyle = {
        height: props.height,
        width: props.width,
        backgroundColor: props.color,
    };

    if(props.style){
        lineStyle = {
            ...lineStyle,
            ...props.style
        }
    }

    return <div style={lineStyle}></div>;
};