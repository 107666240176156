import React, { useEffect, useRef } from 'react';

const SpaceBackground = (props) => {
    const canvasRef = useRef(null);
    const spaceRef = useRef(null);

    const Space = {
        init: function (canvas, context, color, speed) {
            var self = this;
            this.config = {
                perspective: 3,
                star_color: color,
                speed: speed,
                stars_count: 1
            };
            this.canvas = canvas;
            this.context = context;
            this.start();
            window.onresize = function () {
                self.start();
            };
            return this;
        },

        start: function () {
            var self = this;

            this.canvas.width = this.canvas.offsetWidth;
            this.canvas.height = this.canvas.offsetHeight;
            this.canvas_center_x = this.canvas.width / 2;
            this.canvas_center_y = this.canvas.height / 2;

            this.stars_count = this.canvas.width / this.config.stars_count;
            this.focal_length = this.canvas.width / this.config.perspective;
            this.speed = this.config.speed * this.canvas.width / 2000;

            this.stars = [];

            for (var i = 0; i < this.stars_count; i++) {
                this.stars.push({
                    x: Math.random() * this.canvas.width,
                    y: Math.random() * this.canvas.height,
                    z: Math.random() * this.canvas.width,
                });
            }

            window.cancelAnimationFrame(this.animation_frame);
            this.canvas.style.opacity = 1;
            self.render();
        },

        render: function () {
            var self = this;
            this.animation_frame = window.requestAnimationFrame(function () {
                self.render();
            });
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            for (var i = 0, length = this.stars.length; i < length; i += 1) {
                var star = this.stars[i];
                star.z -= this.speed;
                if (star.z <= 0) {
                    this.stars[i] = {
                        x: Math.random() * this.canvas.width,
                        y: Math.random() * this.canvas.height,
                        z: this.canvas.width,
                    };
                }

                var star_x = (star.x - this.canvas_center_x) * (this.focal_length / star.z) + this.canvas_center_x;
                var star_y = (star.y - this.canvas_center_y) * (this.focal_length / star.z) + this.canvas_center_y;
                var star_radius = Math.max(0, 1.4 * (this.focal_length / star.z) / 2);
                var star_opacity = 1.2 - star.z / this.canvas.width;

                this.context.fillStyle = 'rgba(' + this.config.star_color + ',' + star_opacity + ')';
                this.context.beginPath();
                this.context.arc(star_x, star_y, star_radius, 0, Math.PI * 2);
                this.context.fill();
            }
        },

        changeStarColor: function (newColor) {
            this.config.star_color = newColor;
        },

        changeStarSpeed: function (newSpeed) {
            this.config.speed = newSpeed;
        }
    };


    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        window.onload = function () {
            spaceRef.current = Space.init(canvas, context, props.color, props.speed);
        };



        spaceRef.current = Space.init(canvas, context, props.color, props.speed);
        console.log("spaceref!",spaceRef)

        // Cleanup function to stop the animation when the component unmounts
        return () => {
            window.cancelAnimationFrame(Space.animation_frame);
        };
    }, []);

    useEffect(()=>{
        if(spaceRef.current) {
            spaceRef.current.changeStarColor(props.color)
        }
    },[props.color]);

    useEffect(()=>{
        if(spaceRef.current) {
            console.log("speed!",props.speed)
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            spaceRef.current = Space.init(canvas, context, props.color, props.speed);
        }
    },[props.speed]);

    const classes = {
        background: {
            position: "absolute",
            backgroundColor: "black",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1 /* Ensure it's behind other content */
        }
    }

    return <div><canvas id="canvas" ref={canvasRef} style={classes.background} /></div>;
};

SpaceBackground.defaultProps = {
    color: '120, 120, 120',
    speed: 0.5
}

export default SpaceBackground;