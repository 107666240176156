import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography, hexToRgb } from '@mui/material';
import { useEffect, useState } from 'react';
import '../font.css'
import {colors} from '../utils/colors.js'
import CTABlock from '../components/CTABlock.jsx';
import { Paintbrush, RefreshCcwDot, Stars } from 'lucide-react';
import SpaceBackground from '../components/SpaceBackground.jsx';

const classes = {
  mainContainer: {

  },
  container:{

  },
  ctaContainer: {
    background: colors.theme[3]
  },
  containerHeight: {
    'width': '100vw',
    'max-width': '100%',
  },
  headerText: {
    fontSize: '5em',
    fontWeight: 800,
    color: 'white',
    width: '80%'
  },
  canvas: {
      height: "100%",
      width: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      opacity: 0,
  },
  footer: {
    color: colors.descript["offWhite"],
    opacity: 0.5,
    bottom: 0,
    position: "absolute",
    width: "100%",
    textAlign: "center",
    paddingBottom: "1em"
  }
}

// TODO: commit everything (including plugin!!1!) and cleanup the different firebase dirs to share one firebase config in this repo
export default function Home(props){
  const defaultSpaceColor = '120, 120, 120';
  const [spaceColor, setSpaceColor] = useState(defaultSpaceColor);

  useEffect(()=>{
    document.title = props.title
  },[props.title]);

  const hexToSpaceColor = (hex) => {
    let rgb = hexToRgb(hex).replace("rgb(","").replace(")","");
    return rgb;
  }

  return (
    <>
      <div style={classes.container}>
        <Grid container py={8} px={4} style={classes.mainContainer}>
          <Grid xs={8} mx={0} py={15} alignItems='center' justifyContent='center'>
            <Typography style={classes.headerText} py={2}>
            Sync, Create, and Discover LethalCompany Skins
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" py={4} px={0} mx={0} spacing={4} style={classes.ctaContainer}>
          <Grid xs={4} md={3}>
            <CTABlock title="Sync" icon={RefreshCcwDot} buttonTitle="Download Now" onMouseOver={()=>{setSpaceColor(hexToSpaceColor(colors.theme[0]))}} onMouseLeave={()=>{setSpaceColor(defaultSpaceColor)}} href="/sync">
              Seamlessly Sync Your Skins with <br/>Your Entire Lobby, Automatically
            </CTABlock>
          </Grid>
          <Grid xs={4} md={3}>
            <CTABlock title="Create" icon={Paintbrush} buttonTitle="Launch Editor" color={colors.theme[1]} onMouseOver={()=>{setSpaceColor(hexToSpaceColor(colors.theme[1]))}} onMouseLeave={()=>{setSpaceColor(defaultSpaceColor)}} href="/editor">
              Design Skins with the Skin Editor, <br/>with Real-Time 3D Preview
            </CTABlock>
          </Grid>
          <Grid xs={4} md={3}>
            <CTABlock title="Discover" icon={Stars} buttonTitle="Coming Soon" color={colors.theme[2]} onMouseOver={()=>{setSpaceColor(hexToSpaceColor(colors.theme[2]))}} onMouseLeave={()=>{setSpaceColor(defaultSpaceColor)}} href="/discover">
              Share Your Creations and Discover <br/>Skins Created By The Community
            </CTABlock>
          </Grid>
        </Grid>
      </div>
      {/* <div style={classes.footer}>&copy; {(new Date).getFullYear()}. All Rights Reserved.</div> */}
      <SpaceBackground color={spaceColor}/>
    </>
  );
}
