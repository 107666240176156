import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { cursors } from '../utils/cursors';

function ThreePreview(props) {
    const mountRef = useRef(null);
    const modelRef = useRef(null);
    const pivotRef = useRef(null);
    const cameraRef = useRef(null);

    const [isDrawing, setIsDrawing] = useState(false);
    const isDrawingRef = useRef(null);
    isDrawingRef.current = isDrawing;


    const [mouseMode, setMouseMode] = useState(null);
    const mouseModeRef = useRef(null);
    mouseModeRef.current = mouseMode;

    const cursorTimeoutRef = useRef(null);
    cursorTimeoutRef.current = null;

    const drawModeRef = useRef(null);
    drawModeRef.current = props.drawMode;



    const lastMouseLoc = useRef(null);
    lastMouseLoc.current = null;

    const lightColor = 0xFFFACD;


    function restoreDefaultCursor(){
        let selectedCursor = cursors.pencil;
        let x = 0;
        let y = 20;
        if(drawModeRef.current === "fill" || drawModeRef.current == null){
            selectedCursor = cursors.bucket;
        }

        if(props.defaultCursor!==null && props.defaultCursor!==undefined) {
            selectedCursor = props.defaultCursor
        };
        setCursor(selectedCursor, -1, x, y);
    }

    function resetCursor(timeout=0){
        if(cursorTimeoutRef.current != null) window.clearTimeout(cursorTimeoutRef.current)
        cursorTimeoutRef.current = window.setTimeout(function (){
            restoreDefaultCursor();
        }, timeout);
    }
    function setCursor(cursor, timeout=1000, x=16, y=16){

        if(cursor.indexOf("<svg") !== -1){
            cursor = btoa(cursor);
            // console.log("CONVERTED TO", cursor)
        }

        // Encode SVG string to Base64
        // 
        // console.log(encodedSvg)
        // Create Data URL
        const dataUrl = `url('data:image/svg+xml;base64,${cursor}') ${x} ${y}, auto`;
        if(document.getElementById("three-container")) document.getElementById("three-container").style.cursor = dataUrl;

        // threeContainer.style.cursor = `url('${cursor}') 0, 0, auto`;
        // console.log(`url('${cursor}') 0, 0, auto`)
        if(timeout !== -1) resetCursor(timeout);
    }



    useEffect(() => {
        const threeContainer = document.getElementById("three-container");

        let mixer;
        const clock = new THREE.Clock();

        // Scene, camera, renderer
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
        cameraRef.current = camera;
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        camera.position.z = 15;
        camera.position.y = 0;
        
        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);

        mountRef.current.appendChild(renderer.domElement);

        // Ambient Light - Increase intensity
        const ambientLight = new THREE.AmbientLight(lightColor, 2); // Set intensity to 1 for maximum ambient light
        scene.add(ambientLight);

        // Directional Light - Adjust as needed
        const directionalLight = new THREE.DirectionalLight(lightColor, 5);
        directionalLight.position.set(0, 3, 5);
        scene.add(directionalLight);

        // // Hemisphere Light - For softer, more natural light
        // const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 5);
        // scene.add(hemisphereLight);

        // Additional Point Light - Optional
        const pointLight = new THREE.PointLight(lightColor, 50, 50);
        pointLight.position.set(-10, -5, 5);
        scene.add(pointLight);

        // Resize Handler
        const handleResize = () => {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        };

        // Resize Observer
        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(mountRef.current);

        // GLB Loader
        const loader = new GLTFLoader();
        loader.load('Player.glb', function (gltf) {
            
            modelRef.current = gltf.scene;
            let model = modelRef.current;

            
            // Create a group
            const pivot = new THREE.Group();

            // Add your object to the group
            pivot.add(model);

            // Now position the object within the group
            model.position.set(0, 5, 0);

            // Rotate the model
            model.rotation.x = -90 * (Math.PI / 180); // Rotating 90 degrees on X-axis
            
            // scene.add(model);
            scene.add(pivot);

            pivotRef.current = pivot;

            renderSvg();
            
            // Animation
            const animate = function () {
                requestAnimationFrame(animate);
                // model.rotation.z += 0.0025;
                renderer.render(scene, camera);
            };
            animate();

        });

        function onMouseEvent(event) {
            var drawing = isDrawingRef.current;
            var mouseMode = mouseModeRef.current;

            let mouseMapping = ["left","middle","right"]
            const mouseBtn = mouseMapping[event.which-1];
            
            if(event.type === "mousedown"){
                console.log("button",event.which)

                // left click
                if(mouseBtn === "left"){
                    drawing = true;
                    mouseMode = "draw";
                }

                if(mouseBtn === "middle"){
                    mouseMode = "grab";
                }

                if(mouseBtn === "right"){
                    mouseMode = "rotate";
                }
            }
            else if(event.type === "mousemove" && (mouseMode === "rotate" || mouseMode === "grab")){
                
                let deltaX = 0;
                let deltaY = 0;
                if(lastMouseLoc.current != null && Object.keys(lastMouseLoc.current).length !== 0){
                    deltaX = event.x - lastMouseLoc.current.x;
                    deltaY = event.y - lastMouseLoc.current.y;
                }

                if(mouseMode === "rotate"){
                    // bind mouse X movement to Z axis
                    pivotRef.current.rotation.y += deltaX/2 * (Math.PI / 180);

                    // bind mouse Y movement to X axis
                    pivotRef.current.rotation.x += deltaY/2 * (Math.PI / 180);
                    setCursor(cursors.rotate3d);
                }
                else if(mouseMode === "grab"){
                    let modifier = cameraRef.current.position.z/750
                    cameraRef.current.position.y += deltaY * modifier;
                    cameraRef.current.position.x -= deltaX * modifier;
                    console.log(modifier)
                    setCursor(cursors.grab);
                }

                lastMouseLoc.current = {x: event.x, y: event.y}
                
            }
            else if(event.type === "wheel"){
                cameraRef.current.position.z += event.deltaY/500;

                if(event.deltaY < 0){
                    setCursor(cursors.zoomin);
                }
                else{
                    setCursor(cursors.zoomout);
                }
            }
            else if(event.type === "mouseup"){
                drawing = false;
                mouseMode = null;
                lastMouseLoc.current = null;
                restoreDefaultCursor();
            }


            setIsDrawing(drawing);
            setMouseMode(mouseMode);

            if(drawing || (mouseBtn === "left" && (event.type === "mouseup" || event.type === "mousedown"))){
                // Calculate mouse position in normalized device coordinates (-1 to +1)
                const rect = renderer.domElement.getBoundingClientRect();
                mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
                mouse.y = - ((event.clientY - rect.top) / rect.height) * 2 + 1;
            
                // Update the ray with the camera and mouse position
                raycaster.setFromCamera(mouse, camera);
            
                // Calculate objects intersecting the ray
                const intersects = raycaster.intersectObjects(scene.children, true);
            
                if (intersects.length > 0) {
                    const uv = intersects[0].uv; // UV coordinates of the intersection point
                    uvClickHandler(event.type, uv, mouse);
                    // Additional logic based on the UV coordinates can be implemented here
                }
            }
            
            
        }

        
        threeContainer.addEventListener('contextmenu', function(event) {
            event.preventDefault(); // Prevents the default context menu from showing
        });

        threeContainer.addEventListener('wheel', function(event) {
            onMouseEvent(event);
            event.preventDefault();
        });

        threeContainer.addEventListener('mousedown', onMouseEvent, false);
        threeContainer.addEventListener('mouseup', onMouseEvent, false);
        threeContainer.addEventListener('mousemove', onMouseEvent, false);


        // Clean up
        return () => {
            resizeObserver.disconnect();
            if(mountRef.current != null) mountRef.current.removeChild(renderer.domElement);
            threeContainer.removeEventListener('mousedown', onMouseEvent);
            threeContainer.removeEventListener('mouseup', onMouseEvent);
            threeContainer.removeEventListener('mousemove', onMouseEvent);
            threeContainer.removeEventListener('contextmenu', onMouseEvent);
            threeContainer.removeEventListener('wheel', onMouseEvent);
        };
    }, []);

    useEffect(()=>{
        if(props.pngString!=null && modelRef.current!=null){
            renderSvg();
        }
        console.log("load check",props.pngString!=null,modelRef.current!=null)
        
    },[props.pngString])

    useEffect(()=>{
        restoreDefaultCursor();
    },[props.drawMode])

    function renderSvg(){
        const textureLoader = new THREE.TextureLoader();
        console.log("RENDERING from canvas")
        textureLoader.load(props.pngString, function (texture){
            texture.wrapS = THREE.ClampToEdgeWrapping;
            texture.wrapT = THREE.ClampToEdgeWrapping;
            texture.flipY = false;
            modelRef.current.traverse(function (child) {
                if (child.isMesh) {
                    child.material.map = texture;
                    child.material.needsUpdate = true;
                }
            });
        });
    }

    function uvClickHandler(eventType, coordinates, mouse){
        console.log('UV Coordinates:', coordinates);
        props.emulateCanvasClick(eventType, coordinates, mouse);
    }

    return <div id="three-container" ref={mountRef} style={{ width: '100%', height: '100%' }}></div>;
}

export default ThreePreview;
