import React, { useState, useContext, createContext, useRef } from 'react';

const AuthContext = createContext(null);

export const useLSAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [uid, setUid] = useState(null);
    const [user, setUser] = useState(null);
    const lastAuth = useRef(null);


    const login = (auth) => {
        return new Promise((resolve,reject)=>{
            const refreshIndicator = auth.reloadUserInfo.lastRefreshAt; // if this changes, auth.getIdToken().then logic will be called again
            if(auth === null || refreshIndicator === lastAuth.current){
                console.log("auth:: skipping")
                resolve();
            }
            else{
                console.log("auth:: setting", refreshIndicator, lastAuth.current,  refreshIndicator === lastAuth.current)
                auth.getIdToken().then((token)=>{
                    setAuthToken(token);
                    lastAuth.current = refreshIndicator;
                    setUid(auth.uid);
                    setUser(auth);
                    resolve();
                })
            }
        })
    };

    const refreshToken = () => {
        return new Promise((resolve,reject)=>{
            user.getIdToken(true).then((token)=>{
                user.getIdTokenResult().then((idTokenResult) => {
                    // Print all claims
                    console.log("refreshToken",idTokenResult.claims);
            
                    // Access custom claim
                    const partyClaim = idTokenResult.claims.party;
                    console.log('refreshToken claim:', partyClaim);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setAuthToken(token);
                resolve();
            });
        });
    }


    const logout = () => {
        // Perform logout logic here
    };

    return (
        <AuthContext.Provider value={{ uid, login, logout, authToken, refreshToken}}>
            {children}
        </AuthContext.Provider>
    );
};