import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Nav } from "../components/Nav";
import { getAPIInstance } from "../utils/apiInstance";
import User from "../utils/userUtil";
import { colors } from "../utils/colors";
import { HelpCircle, RefreshCcwDot } from "lucide-react";
import FAQAccordion from "../components/FAQAccordion";

export default function Sync (props){
    useEffect(()=>{
        document.title = props.title
    },[props.title]);
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [api, setApi] = useState(null);
    // auth.onAuthStateChanged((userAuth)=>{
    //     if(userAuth == null) console.log("User not signed in - optional") // TODO: show some dialog for signin / signup
    //     else if(user == null || userAuth !== user.userAuth){
    //         console.log(user)
    //         let newUser = new User(userAuth);
    //         setUser(newUser);
    //         newUser.refreshUserData().then((data)=>{
    //             setUserData(data);
    //         });
    //         let api = getAPIInstance(userAuth);
    //         setApi(api);
    //     }
    // });
    const classes = {
        // container: {
        //     minHeight: "100vh",
        //     backgroundColor: colors.theme[4],
        // },
        content: {
            height: "100%",
            display: "flex",
            color: "white",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: "2em"
        },
        headerContainer: {
            display: "flex",
            alignItems: "center",
            fontSize: "26pt",
            fontWeight: "bold",
            color: "white",
            justifyContent: "center",
            width: "100%",
            marginTop: "1em",
            flexDirection: "column"
        },
        headerIcon: {
            backgroundColor: colors.theme[0],
            display: "flex",
            alignItems: "center",
            borderRadius: 4,
            color: colors.theme[4],
            height: "fit-content"
        },
        cardContainer:{
            display: "flex", 
            flexDirection: "row", 
            justifyContent: "flex-start",
            gap: 15,
            marginBottom: "3em",
            position: "relative"
        },
        numberContainer:{
            position: "absolute",
            left: "calc(50% - 25% - 75px)", // Adjust as needed based on the size of the number container
            top: 0,
        },
        number:{
            fontSize: "20pt",
            fontWeight: "600",
            border: "3px solid white",
            borderRadius: "50%",
            height: 40,
            width: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 5,
        },
        card:{
            width: "50%",
            margin: "auto"
        },
        cardHeader:{
            backgroundColor: "rgba(40,44,52,00.42)",
            fontSize: "24pt",
            padding: "5px 10px",
            fontWeight: "bold",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
        },
        cardContent:{
            fontSize: "18pt",
            fontWeight: "600",
            backgroundColor: colors.theme[3],
            padding: "10px 10px",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4
        },
        cardFooter:{
            fontSize: "18pt",
            fontWeight: "bold",
            backgroundColor: "rgba(0,255,102,0.4)",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            padding: "10px 10px",
        }
    }
    return (
        <div>
            <div style={classes.content}>
                <div style={classes.headerContainer}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: 10, color: colors.theme[0]}}>
                        <span style={classes.headerIcon}><RefreshCcwDot size={36}/></span><h2 style={{margin:0, fontSize: "inherit"}}>Sync</h2>
                    </div>
                    <h3 style={{margin: "0.75em", fontSize: "inherit", textAlign: "center"}}>How To: Sync LethalCompany Skins With Friends, Automatically</h3>
                </div>
                <div style={classes.cardContainer}>
                    <div style={classes.numberContainer}>
                        <div style={classes.number}>1</div>
                    </div>
                    <div style={classes.card}>
                        <div style={classes.cardHeader}>
                            Create a LethalSkins Account
                        </div>
                        <div style={classes.cardContent}>
                            One platform for Syncing, Creating, and Discovering LethalCompany Skins
                        </div>
                    </div>
                </div>
                <div style={classes.cardContainer}>
                    <div style={classes.numberContainer}>
                        <div style={classes.number}>2</div>
                    </div>
                    <div style={classes.card}>
                        <div style={classes.cardHeader}>
                            Create a Party + Add Skins
                        </div>
                        <div style={classes.cardContent}>
                            <ul style={{margin: 0}}>
                                <li>From <a href="/dashboard" className="nav-link" style={{color: colors.theme[1]}}>your Account</a>, Click “Create Party” (or join a friend's)</li>
                                <li>Upload Skin PNGs, or create your own in the <a href="/editor" className="nav-link" style={{color: colors.theme[1]}}>Editor</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={classes.cardContainer}>
                    <div style={classes.numberContainer}>
                        <div style={classes.number}>3</div>
                    </div>
                    <div style={classes.card}>
                        <div style={classes.cardHeader}>
                            Set up the Mod
                        </div>
                        <div style={{...classes.cardContent, borderRadius: 0}}>
                            <ul style={{margin: 0}}>
                                <li>Download and Install the <a href="https://thunderstore.io/c/lethal-company/p/PD/LethalSkins/" target="_blank" rel="noreferrer" className="nav-link" style={{color: colors.theme[1]}}>LethalSkins Mod</a></li>
                                <li>While in-game, type in chat: <span style={{color: colors.descript["command"]}}>/syncskins partycode</span></li>
                                <li>Exit & Relaunch LethalCompany (one-time setup)</li>
                                <li>Tell Your friends to do this step using your party code!</li>
                            </ul>
                        </div>
                        <div style={classes.cardFooter}>
                            Done! Skins will auto-sync every time you launch the game!
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10, color: colors.theme[0], fontSize: "26pt", marginBottom: "1em"}}>
                        <span style={classes.headerIcon}><HelpCircle size={36}/></span><h2 style={{margin:0, fontSize: "inherit"}}>FAQ</h2>
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <FAQAccordion/>
                </div>
            </div>
        </div>
    )
}