import { get, off, onValue, ref } from "firebase/database";
import { database } from "../firebaseConfig";
import { atom, atomFamily, selectorFamily } from "recoil";
import { ApiUtil } from "../utils/apiUtil";
import { getAuth } from "firebase/auth";

const syncUserSkinsEffect = (userID) => ({setSelf, trigger}) => {
    if(!userID) return;
    const dataRef = ref(database, "/users/"+userID+"/skins")
    console.log("recoil calling syncUserSkinsEffect", userID)

    // Initialize atom value to the remote storage state
    if (trigger === 'get') { // Avoid expensive initialization        
        let result = get(dataRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }
            else{
                return [];
            }
        })

        if(result == null) result = {}
        setSelf(result) // call synchronously to initialize (pass a promise)
    }

    // Subscribe to remote storage changes and update the atom value
    onValue(dataRef, (snapshot)=>{
        let val = snapshot.val();

        if(val == null) val = {}
        setSelf(val);
    })
    // Cleanup remote storage subscription
    return () => {
        // disable listener
        off(dataRef);
    };
};

export const userSkinsState = atomFamily({
    key: 'UserSkins',
    default: {},
    effects: (userID) => [
        syncUserSkinsEffect(userID)
    ],
});


const syncPartyEffect = (partyID) => ({setSelf, trigger}) => {
    if(!partyID) return;
    let auth = getAuth();
    console.log("recoil calling syncPartyEffect", partyID, auth)

    const dataRef = ref(database, "/parties/"+partyID)
    // Initialize atom value to the remote storage state
    if (trigger === 'get') { // Avoid expensive initialization        
        let result = get(dataRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }
            else{
                return [];
            }
        })
        setSelf(result) // call synchronously to initialize (pass a promise)
    }

    // Subscribe to remote storage changes and update the atom value
    onValue(dataRef, (snapshot)=>{
        let val = snapshot.val();
        setSelf(val);
    })
    // Cleanup remote storage subscription
    return () => {
        // disable listener
        off(dataRef);
    };
};

export const partyState = atomFamily({
    key: 'Party',
    default: {
        skins: null
    },
    effects: (partyID) => [
        syncPartyEffect(partyID)
    ],
});


const syncUserEffect = (userID) => ({setSelf, trigger}) => {
    if(!userID) return;

    let auth = getAuth();
    console.log("recoil calling syncUserEffect auth", auth.currentUser, userID)
    if(auth.currentUser == null){
        setSelf({})
        return
    }
    if(userID == null) return;
    const dataRef = ref(database, "/users/"+userID)
    console.log("syncUserEffect auth syncing user",userID)
    // Initialize atom value to the remote storage state
    if (trigger === 'get') { // Avoid expensive initialization        
        let result = get(dataRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }
            else{
                return [];
            }
        }).catch((err)=>{
            console.warn("syncUserEffect err",err)
        })
        setSelf(result) // call synchronously to initialize (pass a promise)
    }

    // Subscribe to remote storage changes and update the atom value
    onValue(dataRef, (snapshot)=>{
        let val = snapshot.val();
        setSelf(val);
    })
    // Cleanup remote storage subscription
    return () => {
        // disable listener
        off(dataRef);
    };
};

export const userState = atomFamily({
    key: 'User',
    default: {
        uid: null,
        party: null
    },
    effects: (userID) => [
        syncUserEffect(userID)
    ],
});

export const skinPreviewModalState = atom({
    key: 'SkinPreviewModal',
    default: {
        skin: null,
        show: false
    }
})
