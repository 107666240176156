import { colors } from "../utils/colors"
import {Modal, FormGroup, FormControlLabel, Box, Typography, Alert, Switch, Button} from '@mui/material';

import ActionButton from "./ActionButton"
import { forwardRef, useState } from "react"
import { Bug } from "lucide-react";
import { FormTextArea } from "./FormTextArea";
import { FormSelect } from "./FormSelect";
import { SteamLogo } from "../utils/svgLib";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useLSAuth } from "../providers/AuthContext";
import { useRecoilState } from "recoil";
import { ApiUtil } from "../utils/apiUtil";
export const Nav = forwardRef((props, ref)=>{
    const [feedbackError, setFeedbackError] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [justLoggedOut, setJustLoggedOut] = useState(false);
    const navigate = useNavigate();
    const {authToken} = useLSAuth();

    const api = new ApiUtil(authToken);
    const auth = getAuth();
    const handleFeedbackSubmit = () =>{
      const feedbackType = document.getElementById('feedback-type').value;
      const feedbackText = document.getElementById('feedback-text').value;
      const href = window.location.href;

      setFeedbackLoading(true);
      api.submitFeedback({
        type: feedbackType,
        feedback: feedbackText,
        url: href
      }).then(()=>{
        setFeedbackLoading(false);
        setSuccess(true);
      }).catch((err)=>{
        console.log("error")
        if(err === 403){
          setFeedbackError("Sorry, we couldn't submit your feedback at this time. Please try again.");
        }
        else{
          setFeedbackError("An error occurred: "+err);
        }
        
        setFeedbackLoading(false);
      })
    }

    const closeFeedbackModal = () =>{
      setFeedbackError(false);
      setShowFeedbackModal(false);
      setSuccess(false);
    }

    const handleSteamLogin = () => {
      api.getSteamLoginUrl().then((url)=>{
        window.location.href=url;
      })
      // navigate("/api/auth/steam");
    }

    const handleLogout = () => {
      auth.signOut().then(()=>{
        navigate("/");
        setJustLoggedOut(true);
      })
    }


    return (
      <>
        <nav style={classes.navContainer} ref={ref}>
            <div style={classes.navCenterpiece}>
            <a href="/" className="nav-link">
                LethalSkins <span style={classes.badgeContainer}><span style={classes.badge}>Beta</span></span>
            </a>
            </div>
            <div style={classes.navItems}>
                <a href="/sync" className="nav-link">Sync</a>
                <a href="/editor" className="nav-link">Editor</a>
                <a href="/discover" className="nav-link">Gallery</a>
            </div>
            <div style={classes.navButtons}>
                {authToken == null || justLoggedOut ?

                  <>
                    <ActionButton color={colors.descript["offWhite"]} textColor={colors.theme[3]} title="Login with Steam" onClick={handleSteamLogin} icon={<SteamLogo size={30} color={colors.theme[3]}/>}/>
                  </>

                  :
                  <>
                  <ActionButton title="Feedback" icon={Bug} onClick={()=>{setShowFeedbackModal(true)}}/>

                  {window.location.pathname === "/dashboard" ? <ActionButton title="Logout" onClick={handleLogout}/> : <ActionButton title="Dashboard" color={colors.buttons.signup} href="/dashboard"/>}
                  </>
              
              }
                
            </div>
        </nav>
        <Modal
                open={showFeedbackModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
              <Box style={classes.box}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                  Submit Feedback
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {success ?
                    <>
                      <Alert severity="success" style={{marginBottom: "1em"}}>Thank you, your feedback has been submitted.</Alert>
                      <div style={{display: "flex", justifyContent: "flex-end", gap: 10}}>
                        <ActionButton title="Close" onClick={closeFeedbackModal}/>
                      </div>
                    </>
                    :
                    <>
                      {feedbackError ? <Alert severity="error" style={{marginBottom: "1em"}}>{feedbackError}</Alert> : ""}
                      <FormGroup style={{width: "100%"}}>
                          <FormSelect placeholder="Report Type" id="feedback-type">
                            <option value="bug-web">Report a Bug - Website</option>
                            <option value="bug-mod">Report a Bug - Mod</option>
                            <option value="new-feature">Suggest a New Feature</option>
                            <option value="change-request">Suggest a Change</option>
                            <option value="question">Ask a Question</option>
                          </FormSelect>
                          <FormTextArea placeholder="Enter Info Here" id="feedback-text" maxLength={1000} warp="soft"/>
                          <div style={{display: "flex", justifyContent: "flex-end", gap: 10}}>
                              <ActionButton title="Cancel" onClick={closeFeedbackModal}/>
                              <ActionButton title="Send" color={colors.theme[1]} onClick={handleFeedbackSubmit} loading={feedbackLoading}/>
                          </div>
                      </FormGroup>
                    </>
                  }
                  
                  </Typography>
              </Box>
        </Modal>
      </>
    )
});

const classes = {
    navContainer: {
    //   width: '100%',
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      backgroundColor: colors.theme[3],
      padding: "1em 2em",
      fontFamily: "Open Sans",
      position: "relative"
    },
    navGrid: {
      color: "white",
      height: "4em",
      fontFamily: "Open Sans !important",
      fontWeight: 400
    },
    navItems: {
      fontSize: '16pt',
      fontWeight: "bold",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "30%",
    },
    navButtons:{
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      gap: "1.5em"
    },
    navCenterpiece: {
      fontSize: '24pt',
      fontWeight: "bold"
    },
    signup:{
        backgroundColor: colors.theme[0],
        color: 'black',
    },
    login:{
        marginRight: "2em",
        color: colors.theme[0]
    },
    buttonBase:{
        borderRadius: 3,
        height: "3em",
        fontFamily: "Open Sans",
        fontWeight: 800
    },
    badgeContainer: {
      fontSize: "12pt",
      // backgroundColor: colors.theme[0],
      borderRadius: 4,
      color: colors.theme[0],
      padding: "0 0.25em",
      position: "absolute",
      marginLeft: "3px",
      marginTop: "3px"
    },
    badge: {

    },
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      backgroundColor: colors.theme[3],
      color: "white",
      border: '2px solid '+colors.theme[3],
      boxShadow: 24,
      padding: "1em 1.5em",
      borderRadius: 4
    }
  }