import { getAuth} from "firebase/auth";
import { useContext, useState } from "react";
import {ref, set, update } from "firebase/database";
import SkinUploader from "./SkinUploader";
import SkinViewer from "./SkinViewer";
import ActionButton from "./ActionButton";
import { colors } from "../utils/colors";
import {PenSquare, ScanSearch } from "lucide-react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Alert, Box, Modal, Typography } from "@mui/material";
import { FormInput } from "./FormInput";
import { useLSAuth } from "../providers/AuthContext";
import { useRecoilState, useRecoilValue } from "recoil";
import { partyState, userState } from "../recoil/recoilState";
import { ApiUtil } from "../utils/apiUtil";
import { database } from "../firebaseConfig";
const auth = getAuth();

export default function PartyWidget(props){
    const [joinError, setJoinError] = useState(null);
    const [joinLoading, setJoinLoading] = useState(false);
    const [createPartyLoading, setCreatePartyLoading] = useState(false);
    const [showJoinModal, setShowJoinModal] = useState(false);

    const {authToken, uid, refreshToken} = useLSAuth();

    const userData = useRecoilValue(userState(uid));
    const partyData = useRecoilValue(partyState(userData.party));
    const api = new ApiUtil(authToken);
    console.log("widget context: ",partyData.skins);

    // since user claims are updated in the API call, 
    // actually recording the party in the user's /user/uid/party field 
    // occurs after the token has been refreshed with the new claims 
    // so that there isn't a permission denied in the UI when that is 
    // changed on the backend before ui token refresh
    const updateUserParty = (partyCode) => {
        console.log("updateUserParty called with",partyCode)
        return new Promise((resolve,reject)=>{
            refreshToken().then(()=>{
                console.log("updateUserParty refresh token complete");
                let partyRef = ref(database, "/users/"+uid+"/party");
                set(partyRef, partyCode).then(()=>{
                    console.log("updateUserParty partyRef set complete")
                    resolve();
                }).catch((err)=>{
                    console.error("partywidget caught error on set",err)
                })
            })
        })
    }

    const createParty = () =>{
        setCreatePartyLoading(true);

        api.createParty().then((code)=>{
            console.log("party created with code: "+code+" calling updateUserParty");
            updateUserParty(code).then(()=>{
                setCreatePartyLoading(false);
            })
            
        }).catch((err)=>{
            console.error("err creating party", err)
        })
    }

    const clickJoin = () => {
        setJoinLoading(true);
        let joinCode = document.getElementById("join-party-code").value;

        api.joinParty(joinCode).then(()=>{
            setJoinError(null);
            updateUserParty(joinCode).then(()=>{
                setShowJoinModal(false);
                setJoinLoading(false);
            })
        }).catch((err)=>{
            if(err === 404){
                setJoinError("Party does not exist");
            }
            else if(err === 429){
                setJoinError("You recently joined a party. Please wait 10 minutes before joining another.");
            }
            else{
                setJoinError("Unknown error: "+err)
            }
            setJoinLoading(false);
        })
    }

    


    const classes = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: colors.theme[3],
            color: "white",
            border: '2px solid '+colors.theme[3],
            boxShadow: 24,
            padding: "1em 1.5em",
            borderRadius: 4
        }
    }

    if(userData != null){
        if(userData.party === ""){
            return (
                <div style={{display: "flex", flexDirection: "column", gap: 10, alignItems: "center", justifyContent: "center", width: "100%"}}>
                    <div style={{color: colors.descript["offWhite"], fontSize: "18pt", fontWeight: "bold", display: "block", width: "100%", textAlign: "center"}}>Create or Join a Party to Sync with Friends!</div>
                    <div style={{display: "flex", gap: "1em", justifyContent: "center"}}>
                        <ActionButton title="Create Party" color={colors.theme[1]} icon={PenSquare} onClick={createParty} loading={createPartyLoading} loadingText={true}/>
                        <ActionButton title="Join Party" icon={ScanSearch} onClick={()=>{setShowJoinModal(true)}}/>
                    </div>
                    <Modal
                        open={showJoinModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={classes.box}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                            Join Party
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {joinError != null ? <Alert severity="error" style={{marginBottom: "1em"}}>{joinError}</Alert> : ""}

                                <FormInput placeholder="Enter Party Code" id="join-party-code"/>
                                <div style={{display: "flex", justifyContent: "flex-end", gap: 10, marginTop: "1em"}}>
                                    <ActionButton title="Cancel" onClick={()=>{setShowJoinModal(false)}}/>
                                    <ActionButton title="Join" color={colors.theme[0]} onClick={clickJoin} loading={joinLoading}/>
                                </div>
                            </Typography>
                        </Box>
                    </Modal>
                </div>
                // <form>
                //     <input type="text" placeholder="Party Code" onChange={(e) => setJoinCode(e.target.value)}/><input type="button" value="Join Party" onClick={clickJoin}/>
                //     <input type="button" value="Create Party" onClick={createParty}/>
                // </form>
            )
        }
        else{
            if(partyData.skins !== undefined){
                if(partyData.skins === null) return ("") // initial value of skins is null before they load. If there are none in the db, it is undefined. This prevents "Create or upload some skins" when there might be skins about to load
                return (           
                    <SkinViewer skins={Object.keys(partyData.skins)} source="party" key={"skinviewercomp-party"}/>
                )
            }

            // if there are no skins, and the initial pre-load value of null has been overridden with undefined (partyData.skins is undefined = there are actually none in the db)
            else{
                console.log("showing Create or Upload some skins! ", partyData.skins)
                return (
                    <div style={{color: "white", display: "flex", justifyContent: "center", width: "100%", marginTop: "4em"}}>
                        <h3>Create or Upload some skins!</h3>
                    </div>
                )
            }
            
        }
    }
    else{
        return "user data loading"
    }
}