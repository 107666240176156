import { LucideShieldQuestion } from "lucide-react";
import { colors } from "../utils/colors";
import { styled } from "@mui/material";
import ActionButton from "./ActionButton";
function CTABlock(props){
    const classes = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 20
        },
        iconContainer: {
            height: 45,
            width: 45,
            backgroundColor: props.color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4
        },
        icon: {

        },
        headerContainer: {
            display: "flex",
            alignItems: "center",
            gap: "1em"
        },
        bodyText: {
            color: "white",
            // padding: "1em 0",
            fontSize: "20pt",
            fontWeight: "bold"
        }
    }
    const Title = styled('h3')({
            color: props.color,
            fontSize: "26pt",
            fontWeight: "bold",
            padding: 0,
            margin: 0
    })

    const IconComponent = props.icon;

    return (
        <div style={classes.container} onMouseOver={props.onMouseOver} onMouseLeave={props.onMouseLeave}>
            <div style={classes.headerContainer}>
                <span style={classes.iconContainer}><IconComponent size={35}/></span>
                <Title style={classes.title}>{props.title}</Title>
            </div>
            <div style={classes.bodyText}>
                {props.children}
            </div>
            <ActionButton title={props.buttonTitle} color={props.color} fontSize={props.buttonFontSize} href={props.href}/>
        </div>
    )
}

CTABlock.defaultProps = {
    color: colors.theme[0],
    children: "body",
    title: "Title",
    icon: LucideShieldQuestion,
    buttonTitle: "Button",
    buttonFontSize: 20,
    href: "#",
    btnHover: ()=>{}
}

export default CTABlock;