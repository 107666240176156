import { getAuth } from "firebase/auth";
import React, { Suspense, useEffect, useState } from "react";
import { Nav } from "../components/Nav";
import { colors } from "../utils/colors";
import {Helmet} from "react-helmet";
import { useLSAuth } from "../providers/AuthContext";


export function Page(props){
    useEffect(()=>{
        document.title = props.title
    },[props.title]);
    const auth = getAuth();
    // const [user, setUser] = useState(null);
    const {login} = useLSAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(()=>{
        auth.onAuthStateChanged((userAuth) => {
            if(userAuth) {
                login(userAuth).then(() => {
                    setIsAuthenticated(true);
                });
            } else {
                setIsAuthenticated(false);
            }
        });
    },[])

    
    const classes = {
        container: {
            minHeight: "100vh",
            backgroundColor: colors.theme[4],
        },
        content: {
            height: "100%",
        }
    }

    var ScreenComponent = props.screen;
    if(typeof(props.screen) === "function"){
        ScreenComponent = <props.screen/>;
    }

    const ScreenComponentWithProps = React.cloneElement(
        ScreenComponent, 
        { 
            ...props.screenProps
        }
      );
    return (
        <div style={props.preventContainerClass ? null : classes.container}>
             <Helmet>
                <meta charSet="utf-8" />
                <title>{props.title}</title>
            </Helmet>
            <Nav/>
            <Suspense fallback={<div>Loading</div>}>
                {(isAuthenticated || !props.requireAuth) && ScreenComponentWithProps}
            </Suspense>
            
        </div>
    )
}