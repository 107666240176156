import {Pencil, PaintBucket, Undo2, Redo2, Palette, Diameter, FileUp, HardDriveDownload, PlusSquare, Eraser, Pipette, Replace} from "lucide-react";
import { VerticalLine } from "./VerticalLine";
import { ChromePicker } from 'react-color';
import { useState, useRef } from "react";
import {Sketch, Material, Colorful, Compact, Circle, Wheel, Block, Github, Chrome, hsvaToRgba, hsvaToHex } from '@uiw/react-color';

import { Slider, Button, IconButton } from "@mui/material";
import EditorIconButton from "./EditorIconButton";


export const EditorBar = (props) => {
    const iconSize = 24;
    const iconColor = "white";
    const divider = <VerticalLine height="50%" width="2px" color="white"/>;
    const fileInputRef = useRef(null);
    // Color picker needs HSVA, CSS needs hex
    let selectedColorHex = hsvaToHex(props.selectedColor);

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const toggleColorPicker = () => {
        setDisplayColorPicker(!displayColorPicker);
    }

    const [displaySizeSlider, setDisplaySizeSlider] = useState(false);
    const toggleSizeSlider = () => {
        setDisplaySizeSlider(!displayColorPicker);
    }
    const handleSliderChange = (event, value) => {
        props.setBrushSize(value);
    }

    const fileUploadClick = () => {
        fileInputRef.current.click();
    }


    const popover = {
        position: 'absolute',
        zIndex: '2',
        bottom: "6vh",
        padding: "1em",
        backgroundColor: "#282c34",
        borderRadius: 10,
    }
    const sliderPopover = {
        ...popover,
        width: "200px",
        height: "50px"
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }


    return (
        <>
            <div className="editor-bar">
                <EditorIconButton active={props.mode === "draw"} onClick={()=>{props.setMode('draw')}} title="Draw">
                    <Pencil size={iconSize}/>
                </EditorIconButton>

                <EditorIconButton active={props.mode === "fill"} onClick={()=>{props.setMode('fill')}} title="Fill">
                    <PaintBucket size={iconSize}/>
                </EditorIconButton>

                <EditorIconButton active={props.mode === "swap"} onClick={()=>{props.setMode('swap')}} title="Color Swap">
                    <Replace size={iconSize}/>
                </EditorIconButton>

                <EditorIconButton active={props.mode === "pipette"} onClick={()=>{props.setMode('pipette')}} title="Pipette">
                    <Pipette size={iconSize}/>
                </EditorIconButton>

                <div style={{display: "flex"}}>
                    <EditorIconButton onClick={props.undo} disabled={!props.undoAllowed} title="Undo">
                        <Undo2 size={iconSize} style={{marginRight: 8}}/>
                    </EditorIconButton>
                    <EditorIconButton onClick={props.redo} disabled={!props.redoAllowed} title="Redo">
                        <Redo2 size={iconSize}/>
                    </EditorIconButton>
                </div>
                {divider}
                <EditorIconButton onClick={toggleColorPicker} title="Color">
                    <Palette size={iconSize}/>
                    <div style={{height: 24, width: 24, backgroundColor: selectedColorHex, borderRadius: 2, border: "1px solid white", marginLeft: 8}}/>
                </EditorIconButton>
                <EditorIconButton onClick={toggleSizeSlider} title="Brush Size">
                    <Diameter size={iconSize}/>
                    <span style={{color: "white", fontFamily: "Open Sans", marginLeft: 8, fontWeight: "bold", fontSize: 18, width: 22}} className="prevent-select">{props.brushSize}</span>
                </EditorIconButton>
                {divider}

                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={props.upload}
                    style={{ display: 'none' }} // Hidden, triggered by File Upload button
                    accept="image/png"
                />
                <EditorIconButton onClick={fileUploadClick} title="Upload Skin">
                    <FileUp size={iconSize}/>
                </EditorIconButton>

                <EditorIconButton onClick={props.initDownload} title="Download Skin">
                    <HardDriveDownload size={iconSize}/>
                </EditorIconButton>
                
                <Button variant="contained" onClick={props.initSave} startIcon={<PlusSquare color={iconColor} size={iconSize}/>}>
                    Add to Party
                </Button>
                
                
                

            </div>
            { displayColorPicker ? <div style={ popover }>
                <div style={ cover } onClick={()=>{setDisplayColorPicker(false)}}/>
                
                    <Colorful
                        color={props.selectedColor}
                        disableAlpha={true}
                        onChange={(color) => {
                            props.setSelectedColor(color.hsva);
                        }}
                    />

            </div> : null }

            { displaySizeSlider ? <div style={ sliderPopover }>
                <div style={ cover } onClick={()=>{setDisplaySizeSlider(false)}}/>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
                        <div style={{display:"flex", margin: "0 auto", backgroundColor: selectedColorHex, height: props.brushSize, width: props.brushSize, borderRadius: 100}}/>
                        <div style={{display:"flex", width: "80%", marginLeft: 10}}>
                            <Slider 
                                value={props.brushSize}
                                min={1}
                                max={35}
                                aria-label="Brush Size" 
                                valueLabelDisplay="auto" 
                                onChange={handleSliderChange}
                            />
                        </div>
                    </div>
                    
                    

            </div> : null }
        </>
    )
}