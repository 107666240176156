import { CircularProgress } from "@mui/material";
import { colors } from "../utils/colors";
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import React from "react";

function ActionButton(props){
    const navigate = useNavigate();
    const Btn = styled('button')({
        backgroundColor: props.color,
        color: props.textColor,
        padding: "0.5em",
        borderRadius: 4,
        opacity: 1,
        cursor: "pointer",
        textDecoration: "none",
        fontSize: props.fontSize,
        fontFamily: "Open Sans",
        border: 0,
        fontWeight: "700",
        "&:hover": {
            opacity: 0.8
        },
        "&:active": {
            opacity: 0.6
        }
    });

    const classes = {
        button:{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
            borderRadius: 4,
        },
        iconContainer:{
            backgroundColor: "rgba(0,0,0,0.25)",
            // height: "100%",
            display: "flex",
            alignItems: "center",
            alignSelf: "stretch",
            padding: "0 8px",
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
        },
        textContainer:{
            padding: "8px",
            // lineHeight: "8px"
        }
    }

    const onClick = () => {
        if(props.onClick){
            props.onClick();
        }
        else if(props.href !== "#"){
            navigate(props.href);
        }
    }
    const spinner = <CircularProgress size={25}/>;
    const loader = <div style={{display: "flex", alignItems: "center", gap: 10, color: "rgba(0,0,0,0.6)"}}>{spinner}{props.loadingText ? "Loading" : ""}</div>;
    var iconArea = "";

    
    if(props.icon != null){
        var iconComponent;
        if(!React.isValidElement(props.icon)){
            iconComponent = <props.icon/>
        }
        else{
            iconComponent = props.icon;
        }
        iconArea = 
            <div style={classes.iconContainer}>
                {iconComponent}
            </div>
    }
    
    return (
        <Btn onClick={onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} id={props.id} type={props.type} style={{...classes.button, ...props.style}}>
            {iconArea} <span style={classes.textContainer}>{ props.loading ? loader : props.title}</span>
        </Btn>
    )
}


ActionButton.defaultProps = {
    color: colors.buttons["default"],
    textColor: colors.theme[3],
    title: "",
    href: "#",
    fontSize: 16,
    type: "button",
    style: {},
    loadingText: false,
    onMouseEnter: ()=>{},
    onMouseLeave: ()=>{},
    loading: false
};

export default ActionButton;


