import { getAuth} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import PartyWidget from "../components/PartyWidget";
import { useDeferredValue, useEffect, useRef, useState } from "react";

import { Alert, Box, IconButton, Modal, Typography } from "@mui/material";
import { colors } from "../utils/colors";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { CheckIcon, Copy, FileUp, FlaskConical, Group, Heart, Info, Paintbrush, PartyPopper, PenSquare, ScanSearch, Stars, UploadIcon, UserPlus, UsersRound, XCircle } from "lucide-react";
import ActionButton from "../components/ActionButton";
import SkinUploader from "../components/SkinUploader";
import SkinCard from "../components/SkinCard";
import { useLSAuth } from "../providers/AuthContext";
import { skinPreviewModalState, partyState, userSkinsState, userState } from "../recoil/recoilState";
import { useRecoilState, useRecoilValue } from "recoil";
import { ApiUtil } from "../utils/apiUtil";
import SkinViewer from "../components/SkinViewer";
import SkinPreviewModal from "../modals/SkinPreviewModal";

export default function Dashboard(props){
    console.log("DASHBOARD")
    // const [userData, setUserData] = useState(null);
    // const [userSkins, setUserSkins] = useState([]);
    // const [partyData, setPartyData] = useState({});

    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showAddFriendsModal, setShowAddFriendsModal] = useState(false);

    const [leaveModalError, setLeaveModalError] = useState(null);
    const navigate = useNavigate();
    
    const auth = getAuth();
    const {authToken, uid} = useLSAuth();
    const api = new ApiUtil(authToken);

    const userData = useRecoilValue(userState(uid));
    const userSkins = useRecoilValue(userSkinsState(uid))
    const partyData = useRecoilValue(partyState(userData.party))

    console.log("dashboard context: ",userData, userSkins, partyData);

    const leavePartyClick = () =>{
        setShowLeaveModal(true);
    }

    const leaveParty = () =>{
        api.leaveParty(userData.party).then(()=>{
            setShowLeaveModal();
            // refreshUserData();
        }).catch((err)=>{
            console.warn(err);
            if(err === 429){
                setLeaveModalError("You recently joined this party. Please wait at least 10 minutes before leaving.")
            }
        })
    }

    if(true){
        if(userData==null){
            return ("userData null")
        }
        else if(api===null){
            return ("setting up api")
        }
        else{
            const isPartyOwner = partyData!=null && uid === partyData.owner;
            return (
                <>
                    <div style={classes.container}>
                        {/* <Nav user={user} api={api}/> */}
                        <Grid2 container px={4} py={4} spacing={2} style={{maxWidth: "100%"}}>
                            <Grid2 xs={12} md={6} lg={2} >
                                {/* <div style={classes.card}>
                                    <div style={{...classes.cardHeader, color: colors.theme[2]}}>
                                        <div style={classes.left}>
                                            <Heart/> Favorites
                                        </div>
                                        <div style={{...classes.right}}>
                                            <ActionButton title="Discover" icon={Stars} color={colors.theme[2]}/>
                                        </div>
                                    </div>
                                    <div style={classes.cardContent} className="customScroll">
                                    
                                    </div>
                                </div> */}
                                <Alert icon={<FlaskConical fontSize="inherit" />} severity="success" style={{fontSize: "12pt"}}>
                                    <h3 style={{marginTop: 0, fontSize: "12pt"}}>Welcome to the Open Beta</h3>
                                    I hope you find the platform useful for syncing, editing, and (soon) sharing and discovering skins!<br/><br/>
                                    There is a lot planned and a devlog will be available here soon.<br/><br/>
                                    <b>Please submit suggestions, bugs, or questions using the Feedback button!</b>
                                </Alert>
                                <Alert icon={<Info fontSize="inherit" />} severity="info" style={{fontSize: "12pt", marginTop: "0.75em"}}>
                                    <h3 style={{marginTop: 0, fontSize: "12pt"}}>Quick Start</h3>
                                    Create or join a party, create or upload some skins (check out the 3d skin editor!), then install the LethalSkins Mod via your Mod Manager.
                                    <br/><br/>
                                    <ActionButton title="Mod Setup Guide" href="/sync" color={colors.theme[1]}/>
                                </Alert>
                            </Grid2>
                            <Grid2 xs={12} md={6} lg={5}>
                                <div style={classes.card}>
                                    <div style={{...classes.cardHeader, color: colors.theme[0]}}>
                                        <div style={classes.left}>
                                            <PartyPopper/> Party
                                        </div>
                                        
                                        <div style={{...classes.right}}>
                                            {userData.party !== "" ? 
                                                <>
                                                    <div className="tooltip">
                                                        <IconButton onClick={()=>{setShowAddFriendsModal(true)}}>
                                                            <UserPlus color="white"/>
                                                        </IconButton>
                                                        <div className="tooltiptext">
                                                            Add Friends
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="tooltip">
                                                        <SkinUploader party={userData.party}/>
                                                        <div className="tooltiptext">
                                                            Upload
                                                        </div>
                                                    </div>
                                                    <div className="tooltip">
                                                        <IconButton onClick={leavePartyClick}>
                                                            <XCircle color="red"/>
                                                        </IconButton>
                                                        <div className="tooltiptext">
                                                            Leave
                                                        </div>
                                                    </div>
                                                    
                                                </>
                                                
                                            : ""}
                                            
                                        </div>
                                        
                                    </div>
                                    <div style={classes.scrollableWrapper}>
                                        <div style={classes.cardContent} className="customScroll">
                                            <PartyWidget/>
                                        </div>
                                    </div>
                                </div>
                            </Grid2>
                            <Grid2 xs={12} md={6} lg={5} >
                                <div style={classes.card}>
                                    <div style={{...classes.cardHeader, color: colors.theme[1]}}>
                                        <div style={classes.left}>
                                            <Paintbrush/> My Skins
                                        </div>
                                        <div style={{...classes.right}}>
                                            <ActionButton title="Create" icon={Paintbrush} color={colors.theme[1]} href="/editor"/>
                                        </div>
                                    </div>
                                    <div style={classes.cardContent} className="customScroll">
                                            <SkinViewer skins={Object.values(userSkins)} source="myskins"/>
                                    </div>
                                </div>
                            </Grid2>
                        </Grid2>
                    </div>
                    <Modal
                        open={showLeaveModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={classes.box}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                            {isPartyOwner ? "Disband Party" : "Leave Party"}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {leaveModalError != null ? <Alert severity="error" style={{marginBottom: "1em"}}>{leaveModalError}</Alert> : ""}

                            Are you sure you want to {isPartyOwner ? "disband" : "leave"} this party?<br/><br/>
                            <span style={{color: "red"}}>{isPartyOwner ? "You are the creator of this party. Leaving will delete the party for all members." : ""}</span>
                            <div style={{display: "flex", justifyContent: "flex-end", gap: 10, marginTop: "1em"}}>
                                <ActionButton title="Cancel" onClick={()=>{setShowLeaveModal(false)}}/>
                                <ActionButton title={isPartyOwner ? "Disband Party" : "Leave"} color="red" onClick={leaveParty}/>
                            </div>
                            </Typography>
                        </Box>
                    </Modal>
                    <Modal
                        open={showAddFriendsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={classes.box}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                            Party Code: <span style={{color: colors.theme[0], fontWeight: "bold"}}>{userData.party}</span>
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Share this code with friends so they can:
                                <ul>
                                    <li>Add / remove skins here at LethalSkins.io</li>
                                    <li>Sync with the party in-game</li>
                                </ul>
                            To sync your game, <a href="/sync" className="nav-link" style={{color: colors.theme[0]}}>follow this guide</a>
                            <div style={{display: "flex", justifyContent: "flex-end", gap: 10, marginTop: "1em"}}>
                                <ActionButton title="Close" onClick={()=>{setShowAddFriendsModal(false)}}/>
                            </div>
                            </Typography>
                        </Box>
                    </Modal>
                    <SkinPreviewModal/>
                </>
                
            )
        }
        
    }
}

const classes = {
    card: {
        
    },
    cardHeader: {
        backgroundColor: "rgba(40, 44, 52, 1)",
        fontSize: 26,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        gap: 10,
        padding: "0.25em",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: "1.5em"
    },
    cardContent: {
        height: "500px",
        backgroundColor: "rgba(40, 44, 52, 0.5)",
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        display: "flex",
        overflowY: "auto",

        overflowX: "hidden"
    },
    left: {
        display: "inherit",
        alignItems: "inherit",
        gap: "inherit",
        textWrap: "nowrap"
    },
    right: {
        display: "flex",
        justifyContent: "right",
        width: "100%",
        gap: "inherit"
    },
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: colors.theme[3],
        color: "white",
        border: '2px solid '+colors.theme[3],
        boxShadow: 24,
        padding: "1em 1.5em",
        borderRadius: 4
    },

}