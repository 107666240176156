import { styled } from "@mui/material";
import { colors } from "../utils/colors";
export const FormSelect = styled('select')({
  height: "40px",
  borderRadius: 4,
  // backgroundColor: "transparent",
  border: "0px solid white",
  color: "black",
  text: "black",
  display: "flex",
  lineHeight: "60px",
  // paddingTop: "10px",
  fontSize: "16pt",
  fontFamily: "Open Sans",
  marginBottom: "1em",
  paddingLeft: "10px",
  backgroundColor: colors.descript["offWhite"],
  width: "100%",
  boxSizing: "border-box",
  // padding: 0,
  "&::placeholder":{
    color: "black",
    // opacity: 0.75,
    fontFamily: "Open Sans",
    fontSize: "16pt",
    // paddingLeft: "10px",
    // backgroundColor: colors.descript["offWhite"],
  },
  "&:focus":{
    // border: "5px solid white",
    outline: "none"
  }
  })