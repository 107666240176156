import { getUserData } from "../firebaseConfig";

import { getAuth} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ApiUtil } from "./apiUtil";

const auth = getAuth();

class User{
    constructor (userAuth){
        return new Promise((resolve,reject)=>{
            console.log("init userauth from userUtil", userAuth)
            // store firebase user auth object
            this.userAuth = userAuth;
            // init data fields
            this.userData = [];
            this.skins = [];
            // init API
            this.loggedIn = false;
            if(userAuth != null){
                this.initApi(userAuth).then(()=>{resolve(this);})
            }
            else{
                resolve(this);
            }
        })
        
        
    }

    initApi (userAuth){
        return new Promise((resolve,reject)=>{
            this.loggedIn = true;
            console.log("calling from initApi")
            const api = new ApiUtil(userAuth)
            this.api = api;
            api.init().then(()=>{
                Promise.all([this.refreshUserData(), this.refreshSkins()]).then(()=>{
                    resolve();
                })
            });
        })
    }

    refreshUserData (){
        return new Promise((resolve,reject)=>{
            getUserData(this.userAuth.uid).then((data)=>{
                this.userData = data;
                resolve(data)
            });
        });
    }

    refreshSkins(){
        console.log("refresh skins")
        return new Promise((resolve, reject)=>{
            this.api.getSkinsByOwner(this.userAuth.uid).then((data)=>{
                this.skins = data;
                resolve(data);
            })
        })
    }

    handleAuthChange(userAuth){
        return new Promise((resolve,reject)=>{
            console.log("onAuthStateChanged userUtil:",userAuth)
            if(userAuth == null) this.loggedIn = false;
            if(this.api.userAuth !== userAuth){
                console.log("auth status changed, userUtil", userAuth);
                this.userAuth = userAuth;
                this.initApi().then(()=>{
                    resolve();
                });
            }
            else{
                console.log("auth status change ignored")
            }
        });
        
    }

}

export default User;


