import { useState, useRef } from "react";

const EditorIconButton = (props) => {
    let clickHandler = props.onClick;
    let classes = "editor-icon-button";
    if(props.active != null && props.active) classes += " active-button"
    if(props.disabled) {
        classes += " disabled-button"
        clickHandler = () => {console.log("onclick disabled")}; // disable onclick
    }
    const hideTimeout = useRef(null);
    const [showTitle, setShowTitle] = useState(false);
    const [hidePopover, setHidePopover] = useState(true);
    const popoverClasses = "editor-icon-button-popover prevent-select";
    const cssPopoverFadeSpeed = 200; // transition duration from .fadeIn/.fadeOut
    function handleFocus(){
        console.log(props.title)
        if(props.title!==""){
            setHidePopover(false);
            setShowTitle(true);
            clearTimeout(hideTimeout.current)
        }
    }

    function handleUnfocus(){
        if(showTitle) {
            setShowTitle(false);
            hideTimeout.current = setTimeout(()=>{setHidePopover(true)},cssPopoverFadeSpeed);
        }
    }

    return (
        <>
            
            <div className={classes}>
                <div style={{display: "flex"}} onClick={clickHandler} onMouseOver={handleFocus} onMouseOut={handleUnfocus}>
                    {props.children}
                </div>
                <div style={hidePopover ? {visibility: "hidden"} : {}} className={!showTitle ? popoverClasses+" fadeOut" : popoverClasses+" fadeIn"}>
                    <span>{props.title}</span>
                </div>
            </div>
        </>
        
    )
}

EditorIconButton.defaultProps = {
    onClick: () => {}, // Providing a default empty function
    disabled: false,
    title: ""
};

export default EditorIconButton;