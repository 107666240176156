import { useEffect, useState } from "react";
import SkinCard from "./SkinCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
export default function SkinViewer (props){
    if(props.skins!=null){
        return (
            <div style={{width: "99%"}}>
                <Grid2 container p={1} spacing={1} style={{paddingRight: 0}} key={"skinviewer-container-"+props.source}>
                    {props.skins.map((skin)=>{return (<SkinCard key={"skincardcomp-"+props.source+"-"+skin} skin={skin} source={props.source}/>)})}
                </Grid2>
            </div>
            )
    }
    else{
        return(<span>Loading</span>)
    }
    
}