import {Modal, FormGroup, FormControlLabel, Box, Typography, Alert, Switch} from '@mui/material';
import ActionButton from "../components/ActionButton";
import { colors } from '../utils/colors';
import { useEffect, useState } from 'react';
import { FormInput } from '../components/FormInput';
import { useNavigate } from 'react-router-dom';
import { useLSAuth } from '../providers/AuthContext';
import { SteamLogo } from '../utils/svgLib';
import { ApiUtil } from '../utils/apiUtil';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/recoilState';
const { Buffer } = require('buffer');

export default function EditorSaveModal(props){
    const [addToParty, setAddToParty] = useState(null);
    const [guidelinesAgree, setGuidelinesAgree] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(false);
    const navigate = useNavigate();

    const {authToken, uid} = useLSAuth();
    const api = new ApiUtil(authToken);

    const userData = useRecoilValue(userState(uid));

    const party = userData != null && userData.party != null ? userData.party : null;
    async function convertDataURLToBlob(dataURL) {
        const response = await fetch(dataURL);
        const blob = await response.blob();
        return blob;
    }

    const classes = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: colors.theme[3],
            color: "white",
            border: '2px solid '+colors.theme[3],
            boxShadow: 24,
            padding: "1em 1.5em",
            borderRadius: 4
          }
    }

    const closeModal = () => {
        setGuidelinesAgree(false);
        setModalError(null);
        setUploadLoading(false);
        setShowConfirmation(false);
        props.close();
        // document.getElementById('file-input').value = "";
    }

    const handleUpload = async () => {
        const skinName = document.getElementById('skin-name').value;
        if(!guidelinesAgree){
            setModalError("All uploads must comply with the Community Guidelines");
            return;
        }
        if(skinName === "" || skinName === null){
            setModalError("Please set a descriptive name for your skin");
            return;
        }

        setUploadLoading(true);
        console.log("uploading")
        const formData = new FormData();
        const fileBlob = await convertDataURLToBlob(props.file);
        const file = new File([fileBlob], "editor-img.png", {type: "image/png"});
        console.log(props.file, file)
        formData.append('file', file);
        formData.append('name', skinName);
        formData.append('source', 'editor');

        if((party!=null && party!== "" && party!==undefined) && addToParty){
            formData.append('partyCode', party);
        }

        formData.append('public', true);
        formData.append('editor', true);

        try {
            const response = await api.uploadSkin(formData);

            if (!response.ok) {
                console.log(response)
                throw new Error('Network response was not ok');
            }

            const result = await response.text();

            setUploadStatus(response.data);
            setShowConfirmation(true);
        } catch (error) {
            console.error('Error uploading file:', error);
            setModalError('Error uploading file. Ensure it is a PNG file under 5MB');
            setUploadLoading(false);
        }
    };

    const handleSteamLogin = () =>{
        api.getSteamLoginUrl().then((url)=>{
            window.open(url, '_blank');
        })
    }

    var modalContent = 
                <Box style={classes.box}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Finalize Skin
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {modalError != null ? <Alert severity="error" style={{marginBottom: "1em"}}>{modalError}</Alert> : ""}

                    <FormGroup>
                        <FormInput placeholder="Name your skin" id="skin-name" maxLength={32}/>
                        {party===null || party === "" ? 
                            ""
                            :
                            <FormControlLabel id="upload-add-to-party" onChange={(e)=>{setAddToParty(e.target.checked)}} style={{marginBottom: "1em"}} control={<Switch />} label="Add to my party"/>
                        }
                        <FormControlLabel id="upload-guidelines-agree" onChange={(e)=>{setGuidelinesAgree(e.target.checked)}} style={{marginBottom: "1em"}} required control={<Switch />} label="This file complies with the Community Guidelines" />
                        <div style={{display: "flex", justifyContent: "flex-end", gap: 10}}>
                            <ActionButton title="Cancel" onClick={closeModal}/>
                            <ActionButton title="Save" color={colors.theme[1]} onClick={handleUpload} loading={uploadLoading}/>
                        </div>
                    </FormGroup>
                    </Typography>
                </Box>

    if(uid==null){
        modalContent = 
        <Box style={classes.box}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            Please Sign In
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>    
            Sync this skin (and more) with your friends in-game by signing up and creating a party!
            </Typography>
            <div style={{display: "flex", justifyContent: "flex-end", gap: 10, marginTop: "1em"}}>
            <ActionButton title="Close" color={colors.theme[4]} textColor={colors.descript["offWhite"]} onClick={closeModal}/>
            <ActionButton color={colors.descript["offWhite"]} textColor={colors.theme[3]} title="Login with Steam" onClick={handleSteamLogin} icon={<SteamLogo size={30} color={colors.theme[3]}/>}/>
            </div>
        </Box>
    }

    else if(showConfirmation){
        modalContent = 
                    <Box style={classes.box}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        Complete
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>    
                        The skin has been added to your account{addToParty ? " and to your party" : ""}!<br/>
                        </Typography>
                    <div style={{display: "flex", justifyContent: "flex-end", gap: 10, marginTop: "1em"}}>
                        <ActionButton title="My Account" onClick={()=>{navigate('/dashboard')}}/>
                        <ActionButton title="Close" color={colors.theme[1]} onClick={closeModal}/>
                    </div>
                    </Box>
    }

    return (
        <>
            <Modal
                open={props.show}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {modalContent}
            </Modal>
        
        </>
    )
}