import { getAuth} from "firebase/auth";
const auth = getAuth();

export class ApiUtil {
    constructor(authToken){
        this.authHeader = {};
        this.authToken = authToken;
        if(authToken){
            this.authHeader = {
                'Authorization': 'Bearer '+authToken,
            };
        }
        // this.userAuth = userAuth;
        // // this.userData = userData;
        // this.idToken = null;
        // this.init();
    }

    // async functions that need to complete before the user can make api requests. UI should wait for this to complete.
    init(){
        return new Promise((resolve,reject)=>{
            console.log("init userauth", this.userAuth)
            if (this.userAuth == null) {
                console.warn("not signed in")
                return;
            };
            this.userAuth.getIdToken().then((token)=>{
                this.idToken = token;
                this.authHeader = {
                    'Authorization': 'Bearer '+this.idToken,
                };
                console.log("API INIT")
                resolve();
            })
        });
    }

    // creates a new party and returns the partycode
    createParty(){
        return new Promise((resolve,reject)=>{
            fetch('/api/party', 
            {
                method: 'POST',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    result.json().then((json)=>{
                        resolve(json.partyCode);
                    })
                }
                else{
                    reject(result.status);
                }
            });
        });
    }

    getParty(partyCode){
        return new Promise((resolve,reject)=>{
            fetch('/api/party/'+partyCode, 
            {
                method: 'GET',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    result.json().then((json)=>{
                        resolve(json);
                    })
                }
                else{
                    reject(result.status);
                }
            });
        });
    }

    joinParty(code){
        return new Promise((resolve,reject)=>{
            if(code!=null){
                fetch('/api/party/join/'+code, 
                {
                    method: 'POST',
                    headers: new Headers({
                        ...this.authHeader,
                        'Content-Type': 'application/json'
                    })
                }).then((result)=>{
                    if(result.status === 200){
                        resolve();
                    }
                    else{
                        reject(result.status);
                    }
                });
            }
        });
    }

    // leaves a party. If the user owns the party, it will be disbanded
    leaveParty(code){
        return new Promise((resolve,reject)=>{
            if(code!=null){
                fetch('/api/party/leave/'+code, 
                {
                    method: 'POST',
                    headers: new Headers({
                        ...this.authHeader,
                        'Content-Type': 'application/json'
                    })
                }).then((result)=>{
                    if(result.status === 200){
                        resolve();
                    }
                    else{
                        reject(result.status);
                    }
                });
            }
        });
    }

    uploadSkin(formData){
        return new Promise((resolve,reject)=>{
            fetch('/api/skin', {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    ...this.authHeader
                })
            }).then((response)=>{
                resolve(response);
            });
        });
    }

    addSkinToParty(partyCode, skinId){
        return new Promise((resolve,reject)=>{
            fetch('/api/party/'+partyCode+'/skins/'+skinId, 
            {
                method: 'PUT',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    resolve();
                }
            });
        });
    }

    getPartySkins(partyCode){
        return new Promise((resolve,reject)=>{
            fetch('/api/party/'+partyCode+'/skins', 
            {
                method: 'GET',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    result.json().then((json)=>{
                        resolve(json);
                    })
                }
            });
        });
    }

    getSkinsByOwner(uid){
        return new Promise((resolve,reject)=>{
            fetch('/api/user/'+uid+'/skins', 
            {
                method: 'GET',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    result.json().then((json)=>{
                        resolve(json);
                    })
                }
            });
        });
    }

    getSkin(skinId){
        return new Promise((resolve,reject)=>{
            fetch('/api/skin/'+skinId, 
            {
                method: 'GET',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    result.json().then((json)=>{
                        resolve(json);
                    })
                }
            });
        });
    }

    removeSkinFromParty(partyCode, skinId){
        return new Promise((resolve,reject)=>{
            fetch('/api/party/'+partyCode+'/skin/'+skinId, 
            {
                method: 'DELETE',
                headers: new Headers({
                    ...this.authHeader,
                    'Content-Type': 'application/json'
                })
            }).then((result)=>{
                if(result.status === 200){
                    resolve();
                }
            });
        });
    }

    submitFeedback(formData){
        return new Promise((resolve,reject)=>{
            fetch('/api/feedback', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: new Headers({
                    ...this.authHeader
                })
            }).then((response)=>{
                if(response.status === 200) resolve(response);
                else reject(response.status)
                
            });
        });
    }

    getSteamLoginUrl(){
        return new Promise((resolve,reject)=>{
            fetch('/api/auth/steam/', {
                method: 'GET',
            }).then((response)=>{
                if(response.status === 200){
                    response.text().then((body)=>{
                        resolve(body);
                    })
                }
                else reject(response.status)
            }).catch((err)=>{
                console.log(err)
                reject(err);
            })
        });
    }

    doSteamAuth(urlParams){
        return new Promise((resolve,reject)=>{
            fetch('/api/auth/steam/authenticate'+urlParams, {
                method: 'GET',
            }).then((response)=>{
                if(response.status === 200){
                    response.json().then((json)=>{
                        resolve(json);
                    })
                }
                else reject(response.status)
                
            });
        });
    }

}

/*
update(ref(database, '/users/' + auth.currentUser.uid), {
                    party: code,
                    lastJoin: Date.now()
                }).then((data)=>{
                    console.log("created db record")
                    console.log(data)
                    props.refreshUserData();
                }).catch((error) => {
                    console.error("Error writing to database:", error);
                });
*/
