import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import moment from 'moment';
import { colors } from '../utils/colors';
import { CircularProgress, IconButton, Skeleton } from '@mui/material';
import { Clock, Delete, DownloadCloud, Eye, EyeIcon, Loader, Paintbrush, PartyPopper, Trash, Trash2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useLSAuth } from '../providers/AuthContext';
import { ApiUtil } from '../utils/apiUtil';
import { useRecoilState, useRecoilValue } from 'recoil';
import { skinPreviewModalState, partyState, userState } from '../recoil/recoilState';

export default function SkinCard(props){
    const imgRef = useRef(null);
    const eyeIconRef = useRef(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [timeAgo, setTimeAgo] = useState(null);

    const [inParty, setInParty] = useState(false); 
    
    const [skin, setSkin] = useState(false);

    const {authToken, uid} = useLSAuth();

    const userData = useRecoilValue(userState(uid));
    const partyData = useRecoilValue(partyState(userData.party));

    const api = new ApiUtil(authToken);

    moment.updateLocale('en', {
        relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'moments',
            ss: '%d sec',
            m: '1min',
            mm: '%dmin',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1mo',
            MM: '%dmo',
            y: '1y',
            yy: '%dy'
        }
    });

    const updateTimeAgo = () => {
        if(partyData != null && partyData.skins != null && partyData.skins !== undefined && Object.keys(partyData.skins).includes(skin.id)){
            let addedAt = partyData.skins[skin.id].added;
            setTimeAgo(moment(addedAt).fromNow());
        }
    }

    useEffect(()=>{
        console.log("full rerender card context:", props.skin)
        console.log("skincard with source",props.source, "rendering skin",props.skin)
        api.getSkin(props.skin).then((result)=>{
            console.log(authToken)
            setSkin(result)
            console.log("Skincard got skin",result)
        });
    },[]);

    useEffect(()=>{
        console.log("partydata changed card context:", props.source, props.skin, partyData, partyData.skins)

        console.log("skinCard partyData",partyData)

        var timeAgoInterval = null;

        if(partyData.skins === undefined || partyData.skins === null){
            setInParty(false);
        }
        else if(props.source === "myskins" && partyData!=null && partyData.skins != null){
            setInParty(Object.keys(partyData.skins).includes(props.skin));
        }
        else if (props.source === "party" && partyData!=null && partyData.skins != null){
            updateTimeAgo();
            timeAgoInterval = setInterval(updateTimeAgo, 10 * 1000);
        }

        return () => {
            if(timeAgoInterval!=null) clearInterval(timeAgoInterval);
        }
    },[partyData])

    useEffect(()=>{
        updateTimeAgo();
    }, [skin])



    const classes = {
        container: {
            display: "flex",
            padding: "0.25em",
            color: "white",
            gap: "0.5em",
            height: "fit-content",
            backgroundColor: colors.theme[3],
            borderRadius: 4,
        }
    }
    

    const handleDelete = () => {
        setDeleteLoading(true);
        api.removeSkinFromParty(userData.party, skin.id).then(()=>{
            setDeleteLoading(false);
            // setDeleted(true);
            // props.onSkinDelete();
        });
    }

    const handleAddToParty = () =>{
        api.addSkinToParty(userData.party, skin.id);
        // props.refresh();
    }

    const [skinPreviewModal, setSkinPreviewModal] = useRecoilState(skinPreviewModalState);
    const handleImageClick = () =>{
        console.log("image click", skin)
        setSkinPreviewModal(
            {
                skin: skin,
                show: true
            }
        )
    }

    const handleImgMouseEnter = () =>{
        imgRef.current.style.opacity = 0.3; 
        eyeIconRef.current.style.opacity = 1;
    }

    const handleImgMouseLeave = () =>{
        imgRef.current.style.opacity = 1; 
        eyeIconRef.current.style.opacity = 0;
    }

    // if(deleted) return;
    if(skin === false) return (
        <Grid2 item xs={12} md={12} lg={12} xl={6} >
            <div style={classes.container}>
                <div style={{display: "flex"}}>
                    <Skeleton variant="rectangular" width={100} height={100}/>
                </div>
                <div style={{flexGrow: 1, flexDirection:"column", position: "relative", overflow: "hidden"}}>
                    <Skeleton variant="text" sx={{fontSize: "16pt"}}/>
                    
                    <Skeleton variant="text" sx={{fontSize: "14pt"}}/>
                    <Skeleton variant="circular" width={25} height={25} style={{alignSelf: "flex-start", padding: 0, position: "absolute", bottom: 0}}/>
                    
                </div>
            </div>
        </Grid2>
    )
    return (
        <Grid2 item xs={12} md={12} lg={12} xl={6} >
            <div style={classes.container}>
            <div style={{ display: "flex", position: "relative", cursor: "pointer" }} onMouseEnter={handleImgMouseEnter} onMouseLeave={handleImgMouseLeave} onClick={handleImageClick}>
                <img src={skin.url} alt={skin.name} height={100} width={100} style={{ borderRadius: 4, transition: "opacity 0.3s" }} ref={imgRef}/>
                <EyeIcon style={{ position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%", opacity: 0, transition: "opacity 0.3s"}} ref={eyeIconRef}/>
            </div>
                <div style={{flexGrow: 1, flexDirection:"column", position: "relative", overflow: "hidden"}}>
                    <div style={{fontSize: "16pt", fontWeight: 500, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{skin.name}</div>
                    {skin.public && props.source === "party" ? <div style={{display: "flex", alignItems: "center", color: colors.theme[1], gap:3}}><Paintbrush size={16}/> {skin.ownerDisplayName}</div> : ""}
                    {props.source === "party" ? 
                    <>
                        <div style={{display: "flex", alignItems: "center", gap:3}}><Clock size={16}/> {timeAgo}</div>
                        <IconButton style={{alignSelf: "flex-start", padding: 0, position: "absolute", bottom: 0}} aria-label="delete" onClick={handleDelete}>
                            {deleteLoading ? <CircularProgress size={20} color="error"/> : <Trash2 color="red"/>}
                        </IconButton>
                    </>

                    :

                    <div style={{position: "absolute", bottom:0, display: "flex", gap: 10, color: "white", alignItems: "flex-end", width: "100%", justifyContent: "space-between"}}>
                        <span style={{display: "flex", alignItems: "center", gap: 10}}><DownloadCloud/> {skin.metrics.downloads}</span>
                        <IconButton aria-label="delete" disabled={inParty} style={{margin: 0, padding: 0}} onClick={handleAddToParty}>
                            <PartyPopper color={inParty ? colors.theme[0] : "white"}/>
                        </IconButton>
                        
                    </div>
                
                    }
                    
                </div>
            </div>
        </Grid2>
    )
}