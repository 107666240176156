import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { fabric } from 'fabric';
import ThreePreview from '../components/ThreePreview';
import { EditorBar } from '../components/EditorBar';
import { VerticalLine } from '../components/VerticalLine';
import { hsvaToHex, hsvaToRgba, rgbaToHsva } from '@uiw/react-color';
import EditorSaveModal from '../modals/EditorSaveModal';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import User from '../utils/userUtil';
import { Nav } from '../components/Nav';
import { useLSAuth } from '../providers/AuthContext';
import { ApiUtil } from '../utils/apiUtil';
// import 'fabric-history';

const EditorCanvas = React.memo(function (props) {

    // Object Refs
    const canvasRef = useRef(null);
    const fabricImageRef = useRef(null);
    const fabricCanvasRef = useRef(null); // useRef to store the Fabric.js canvas instance
    const canvasPaneRef = useRef();
    const headerRef = useRef(null);
    const disableHistory = useRef(false);
    const disableRender = useRef(false);

    const currHistoryStep = useRef(-1);

    // 3D Object State Controller
    const [pngString, setPngString] = useState(null);

    // Pre-req / Precheck state
    const [precheck, setPrecheck] = useState(null);

    // Save modal window state
    const [showSaveModal, setShowSaveModal] = useState(false);

    // History (undo, redo) config
    // const [history, setHistory] = useState([])
    const history = useRef([]);
    const [redoAllowed, setRedoAllowed] = useState(false);
    const [undoAllowed, setUndoAllowed] = useState(false);

    // Tool Mode/Setting Refs
    const [currMode, setCurrMode] = useState('draw');
    const modeRef = useRef();
    modeRef.current = currMode;

    const [brushSize, setBrushSize] = useState(5);

    // The HSVA color selected by the color picker (HSVA format is needed for smooth display in the color picker)
    const [selectedColor, setSelectedColor] = useState({"h":0,"s":0,"v":100,"a":1}); //default: white
    const selectedColorRef = useRef(null);
    selectedColorRef.current = selectedColor;

    // The hex representation of selectedColor, converted by a useEffect
    const [brushColor, setBrushColor] = useState('#fff'); // default: white
    const brushColorRef = useRef(null); // useRef to store the Fabric.js canvas instance
    brushColorRef.current = brushColor;

    // Variable Refs
    const drawnPointsRef = useRef(null);
    drawnPointsRef.current = 0;

    const prevZoomRef = useRef(null);
    const prevViewportRef = useRef(null);

    const panningRef = useRef(null);


    function resetCanvasZoom(){
        let canvas = fabricCanvasRef.current;

        prevZoomRef.current = canvas.getZoom();
        prevViewportRef.current = canvas.viewportTransform.slice(); // Clone the original viewportTransform

        const center = canvas.getCenter();
        canvas.setZoom(1);
        // Setting the viewportTransform to the new calculated values
        canvas.viewportTransform = [
            1, 0, 0, 1,
            center.left - canvas.getWidth() * 1 / 2,
            center.top - canvas.getHeight() * 1 / 2
        ];

        canvas.renderAll();

    }

    function restoreCanvasZoom(){
        let canvas = fabricCanvasRef.current;
        canvas.setViewportTransform(prevViewportRef.current);
        canvas.setZoom(prevZoomRef.current);
    }

    function rescaleImageToFitCanvas() {
        const canvas = fabricCanvasRef.current;
        const img = fabricImageRef.current;
        // Calculate the scale factors for width and height
        const scaleX = canvas.width / img.width;
        const scaleY = canvas.height / img.height;
    
        // Determine the smallest scale factor (to maintain aspect ratio)
        const scale = Math.min(scaleX, scaleY);
    
        // Apply scaling to the image
        img.scale(scale);
    
        // Optionally, center the image on the canvas
        img.set({
            left: canvas.width / 2,
            top: canvas.height / 2,
            originX: 'center',
            originY: 'center'
        });
    
        // Render the canvas
        canvas.renderAll();
    }
    function runPrecheck(){
        // eslint-disable-next-line no-restricted-globals
        var isAtMaxWidth = screen.availWidth - window.innerWidth === 0;
        var screenPixelRatio = (window.outerWidth - 8) / window.innerWidth;
        var isAtDefaultZoom = screenPixelRatio > 0.92 && screenPixelRatio <= 1.09;

        let result = {pass: true}
        if(!isAtDefaultZoom){
            result = {
                pass: false,
                message: "Please set your browser's zoom level to 100% to continue using the editor"
            };
        }
        else if(!isAtMaxWidth){
            result = {
                pass: false,
                message: "LethalSkins Editor requires your browser to be maximized (using the full monitor). Please resize your browser window to continue."
            };
        }
        
        setPrecheck(result);
        return result;
    }

    function calculateCanvasDimensions(){
        let width = window.innerWidth/2;
        let height = window.innerHeight - headerRef.current.clientHeight;
        let selected = Math.min(width,height);

        // fix rounding errors in the history canvas.toJSON() objects, was rounding scale of 1.215 to 1.22
        fabric.Object.NUM_FRACTION_DIGITS = 3; // this shouldn't need to be dynamic (since x/1000 will have 3 decimal points), but putting it here just in case. Scale calculation is: selected/1000
        return selected;
    }
    function resetHistory(){
        currHistoryStep.current = 0;
        history.current = [fabricCanvasRef.current.toJSON()];
        setRedoAllowed(false);
        setUndoAllowed(false);
    }
    function initCanvas(){
        // eslint-disable-next-line no-restricted-globals
        let precheckResult = runPrecheck();

        if(precheckResult.pass){
            let dimension = calculateCanvasDimensions();

            // edge case - the window was loaded reduced and then resized (so fabricCanvasRef.current wasn't created on initial render)
            if(dimension && !fabricCanvasRef.current){
                loadCanvas(dimension);
                changeBrushColor(brushColor);
                changeBrushSize(brushSize);
            }

            return dimension;
        }
        return false;
    }
    function loadCanvas(dimension){
        const canvas = new fabric.Canvas(canvasRef.current, {
            height: dimension,
            width: dimension,
            isDrawingMode: true,
            fireRightClick: true,  // <-- enable firing of right click events
            fireMiddleClick: true, // <-- enable firing of middle click events
            stopContextMenu: true, // <--  prevent context menu from showing
            selection: false,
        });


        canvas.on('mouse:down', function(options) {
            options.e.preventDefault();
            
            // middle mouse button
            if(options.e.button === 1){
                options.e.preventDefault();
                panningRef.current = true;
                return false;
            }
            // other mouse buttons
            else{
                handleCanvasMouseDown(options);
            }
        });

        fabricCanvasRef.current = canvas;

        setCanvasImg('PlayerTexture.png');

        canvas.on('object:added', handleChange);
        canvas.on('object:modified', handleChange);
        canvas.on('object:removed', handleChange);

        canvas.on('mouse:wheel', function(opt) {
            const delta = opt.e.deltaY;
            let zoom = canvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 1) zoom = 1;

            let zoomCoords = screenToCanvasCoordinates(opt.pointer.x, opt.pointer.y); // zoom to mouse by default
            
            const zoomPoint = new fabric.Point(zoomCoords.x, zoomCoords.y);
            canvas.zoomToPoint(zoomPoint, zoom);

            // if at max zoom out (1), recenter the canvas
            if(zoom === 1){
                resetCanvasZoom();
            }

            canvas.requestRenderAll();

            opt.e.preventDefault();
            opt.e.stopPropagation();
        });

        canvas.on('mouse:up', function(opt) {
            panningRef.current = false;
            // experimental - prevent moving drawn lines - maybe there is a more effecient way
            canvas.forEachObject(function(object) {
                object.selectable = false; // Disables selection
                object.evented = false;    // Disables mouse events
            });
            clearBrushPoints();

        });
        canvas.on('mouse:move', function(opt) {
            if (panningRef.current != null && panningRef.current && opt && opt.e) {
                const delta = new fabric.Point(opt.e.movementX, opt.e.movementY);
                canvas.relativePan(delta);
            }
        });
    }


    useLayoutEffect(() => {
        window.addEventListener('resize', initCanvas);
        
        return () => window.removeEventListener('resize', initCanvas);
    }, []);
    useEffect(() => {
        initCanvas();
        const handleKeyPress = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'Z') {
                redo();
            }
            else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                undo();
            }
        };

        // Add event listener
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    useEffect(()=>{
        document.title = props.title
    },[props.title]);

    const handleChange = () => {
        sendSvg();
    };

    function logDebugDetails(){
        let canvas = fabricCanvasRef.current;
        console.log("zoom", canvas.getZoom())
        console.log("viewport", canvas.viewportTransform.slice())
        console.log("center", canvas.getCenter())
        console.log("dimensions", canvas.getHeight(), canvas.getWidth())
        console.log("img dimensions", fabricImageRef.current.height, fabricImageRef.current.width)
        console.log("img scale", fabricImageRef.current.scaleX, fabricImageRef.current.scaleY)
        console.log("history", history.current)
        console.log("history step", currHistoryStep.current)
    }

    // Fixes edge cases where lines are getting re-drawn between uses, especially when undoing a line drawn on the canvas and then drawing on the 3D object
    function clearBrushPoints(){
        fabricCanvasRef.current.freeDrawingBrush._points = []
    }

    const checkUndoAllowed = () =>{
        return currHistoryStep.current > 0;
    }
    const undo = () => {
        if(checkUndoAllowed()){
            let canvas = fabricCanvasRef.current;
            disableHistory.current = true;
            disableRender.current = true;
            currHistoryStep.current--;
            canvas.loadFromJSON(history.current[currHistoryStep.current], function() {
                // render once at the end with historty still disabled (so this render doesn't get recorded as a history step)
                disableRender.current = false;
                sendSvg();
                // now history can be re-enabled
                disableHistory.current = false;

                setUndoAllowed(checkUndoAllowed());
                setRedoAllowed(checkRedoAllowed());

                canvas.renderAll();
            },function(o,object){
                console.log(o,object)
            })
        }
        else{
            console.log("undo skipped")
        }
        
    };

    const checkRedoAllowed = () =>{
        return currHistoryStep.current < history.current.length-1;
    }
    const redo = () => {
        if(checkRedoAllowed()){
            let canvas = fabricCanvasRef.current
            disableHistory.current = true;
            disableRender.current = true;
            currHistoryStep.current++;
            canvas.loadFromJSON(history.current[currHistoryStep.current], function() {
                disableRender.current = false;
                sendSvg();
                disableHistory.current = false;

                setUndoAllowed(checkUndoAllowed());
                setRedoAllowed(checkRedoAllowed());

                canvas.renderAll();
            },function(o,object){
                console.log(o,object)
            })
        }
        else{
            console.log("redo skipped")
        }
        
    };

    const handleCanvasMouseDown = (options) => {
        let canvas = fabricCanvasRef.current;
        // left mouse button
        if(modeRef.current === 'fill'){
            let pointer = canvas.getPointer(options.e);
            let x = Math.round(pointer.x);
            let y = Math.round(pointer.y);
            let fillColor = hexToRgba(brushColorRef.current); // Green color in RGBA
            applyFloodFill(canvas, x, y, fillColor);
            handleChange();
        }

        if(modeRef.current === 'pipette'){
            resetCanvasZoom();
            let pointer = canvas.getPointer(options.e);
            let x = Math.round(pointer.x);
            let y = Math.round(pointer.y);

            const context = canvas.getContext('2d');
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            const targetColor = getColorAtPixel(canvas, data, x, y);
            let color = rgbaToHsva({
                r: targetColor[0],
                g: targetColor[1],
                b: targetColor[2],
                a: targetColor[3]
            });
            setSelectedColor(color)
            restoreCanvasZoom();
        }

        if(modeRef.current === 'swap'){
            let pointer = canvas.getPointer(options.e);
            let x = Math.round(pointer.x);
            let y = Math.round(pointer.y);

            const context = canvas.getContext('2d');
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            const targetColor = getColorAtPixel(canvas, data, x, y);
            let color = rgbaToHsva({
                r: targetColor[0],
                g: targetColor[1],
                b: targetColor[2],
                a: targetColor[3]
            });
            console.log("swapping color", color, "for", selectedColorRef.current)
            applyColorSwap(hsvaToRgba(color), hsvaToRgba(selectedColorRef.current))
        }
    }

    function screenToCanvasCoordinates(screenX, screenY) {
        let canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();  // Get canvas position and size
        const scaleX = canvas.width / rect.width;    // Relationship bitmap vs. element for X
        const scaleY = canvas.height / rect.height;  // Relationship bitmap vs. element for Y
    
        // Calculate the relative canvas coordinates
        const canvasX = (screenX - rect.left) * scaleX;
        const canvasY = (screenY - rect.top) * scaleY;
    
        return { x: canvasX, y: canvasY };
    }
        
    function getCanvasCoordinates(fabricCanvas, normalizedX, normalizedY) {
        const canvasWidth = fabricCanvas.getWidth();
        const canvasHeight = fabricCanvas.getHeight();
    
        const x = Math.round(normalizedX * canvasWidth);
        const y = Math.round(normalizedY * canvasHeight);
    
        return { x, y };
    }

    function emulateDrawing(fabricCanvas, x, y, isEnd = false) {        
        const deltaThreshold = 20; // orig: 5
        
        if (!fabricCanvas.freeDrawingBrush._points) {
            fabricCanvas.freeDrawingBrush._prepareForDrawing({ x, y });
            fabricCanvas.freeDrawingBrush._captureDrawingPath({ x, y });
        } else {
            fabricCanvas.freeDrawingBrush._captureDrawingPath({ x, y });
        }

        for(let i = 0; i < fabricCanvas.freeDrawingBrush._points.length; i++){
            if(i>0){
                let deltaX = Math.abs(fabricCanvas.freeDrawingBrush._points[i].x - fabricCanvas.freeDrawingBrush._points[i-1].x)
                let deltaY = Math.abs(fabricCanvas.freeDrawingBrush._points[i].y - fabricCanvas.freeDrawingBrush._points[i-1].y)
                // if some point in the current line/path's points jumps more than deltaThreshold pixels, delete everything after an including the jump and end the line
                if(deltaX > deltaThreshold || deltaY > deltaThreshold){
                    fabricCanvas.freeDrawingBrush._points = fabricCanvas.freeDrawingBrush._points.slice(0, i);
                    isEnd = true;
                    break;
                }
            }
        }

        if(isEnd){ 
            // this logic is always needed, since in rare cases a point can be the start and end of a path
            fabricCanvas.freeDrawingBrush._finalizeAndAddPath();
            delete fabricCanvas.freeDrawingBrush._points; // Clean up
        }

        
    
        fabricCanvas.renderAll();
    }


    function emulateCanvasClick(type, normalizedCoords, mouse) {
        var normalizedX = normalizedCoords.x;
        var normalizedY = normalizedCoords.y;

        let fabricCanvas = fabricCanvasRef.current;
        const { x, y } = getCanvasCoordinates(fabricCanvas, normalizedX, normalizedY);

        type = type.replace("mouse","mouse:"); // convert mousemove to mouse:move, mousedown = mouse:down, etc

        if(modeRef.current === "draw"){
            drawnPointsRef.current++;
            var isEnd = type==="mouse:up" || drawnPointsRef.current % 10 === 0; // always draw points if it's mouseUp (to end the line), or end at every nth sampled point so it is rendered more frequently

            disableHistory.current = type!=="mouse:up"; // only allow the history to get recorded once, on mouse:up

            emulateDrawing(fabricCanvas, x, y, isEnd);
        }
        else if(modeRef.current === "fill" && type==="mouse:down"){
            let fillColor = hexToRgba(brushColorRef.current); // Green color in RGBA
            applyFloodFill(fabricCanvas, x, y, fillColor);
        }
    }
    

    const sendSvg = () => {
        if (fabricCanvasRef.current && !disableRender.current) {
            let canvas = fabricCanvasRef.current;
            resetCanvasZoom();
            let multiplier = 1000/canvas.getHeight()
            let png = canvas.toDataURL({
                format: 'png',
                multiplier: multiplier // Adjust for higher or lower resolution
            });
            setPngString(png);
            
            restoreCanvasZoom();

            // if this render wasn't caused by an undo/redo or some multi-render action (such as drawing on the 3D object), record it in history
            if(!disableHistory.current){
                let newHistory = [...history.current.slice(0, currHistoryStep.current+1), canvas.toJSON()]
                history.current = newHistory;
                currHistoryStep.current++;

                console.log("history curr step", currHistoryStep);
                console.log("history size", newHistory.length)

                // since the history changed, check if undo/redo is allowed
                setUndoAllowed(checkUndoAllowed());
                setRedoAllowed(checkRedoAllowed());
            }
        }
    };

    function initDownload(){
        // Create a link to download the canvas pngString (state variable) as PNG
        const link = document.createElement('a');
        link.download = "lethalskins-"+Date.now();
        link.href = pngString;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function upload(event){
        
        const file = event.target.files[0];

        if (file && file.type.startsWith('image/png')) {
            const reader = new FileReader();

            reader.onloadend = () => {
                clearCanvas();
                // This will be a data URL of the uploaded PNG
                setCanvasImg(reader.result, function(){
                    resetHistory();
                });
            };

            reader.readAsDataURL(file);
        } else {
            console.error('Please upload a PNG image.');
        }
        
    }
    function clearCanvas(){
        fabricCanvasRef.current.clear();
    }
    function setCanvasImg(url, callback=()=>{}){
        let canvas = fabricCanvasRef.current;
        fabric.Image.fromURL(url, function (img) {
            if (canvas) {
                fabricImageRef.current = img;
                const options = {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height
                };

                canvas.setBackgroundImage(img, function() {
                    if (fabricCanvasRef.current) {
                        sendSvg(); // Send the original SVG on init
                        canvas.renderAll(); // Render after setting the background
                        callback();
                    }
                }, options);
            }
        });
    }

    // Color selector event handler (convert HSVA to hex and save to brushColor)
    useEffect(()=>{
        setBrushColor(hsvaToHex(selectedColor));
    },[selectedColor])

    // Brush color event handler
    useEffect(()=>{
        // when the state changes, change the freeDrawingBrush color
        changeBrushColor(brushColor);
    },[brushColor])

    const changeBrushColor = (val) => {
        // if(val.indexOf("rgba")!==-1) val = 
        if(fabricCanvasRef.current !== null) fabricCanvasRef.current.freeDrawingBrush.color = val; // Replace with your color
    }
    useEffect(()=>{
        changeBrushSize(brushSize);
    },[brushSize]);
    const changeBrushSize = (val) => {
        if(fabricCanvasRef.current!==null) fabricCanvasRef.current.freeDrawingBrush.width = parseInt(val, 10)
    }





    function hexToRgba(hex) {
        console.log("hex",hex)
        // Remove the hash at the start if it's there
        hex = hex.replace(/^#/, '');
    
        // Parse the hex color string
        let r, g, b, a = 255; // Default alpha value is 255 (fully opaque)
    
        if (hex.length === 3) {
            // Short form without alpha (#RGB)
            r = parseInt(hex[0] + hex[0], 16);
            g = parseInt(hex[1] + hex[1], 16);
            b = parseInt(hex[2] + hex[2], 16);
        } else if (hex.length === 6) {
            // Long form without alpha (#RRGGBB)
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
        } else if (hex.length === 8) {
            // Long form with alpha (#RRGGBBAA)
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
            a = parseInt(hex.substring(6, 8), 16);
        } else {
            throw new Error('Invalid hex color: ' + hex);
        }
    
        return [r, g, b, a];
    }

    const applyColorSwap = (targetColor, replaceColor, tolerance = 20) => {
        targetColor = [targetColor.r, targetColor.g, targetColor.b, targetColor.a];
        replaceColor = [replaceColor.r, replaceColor.g, replaceColor.b, replaceColor.a];

        resetCanvasZoom();
        console.log(targetColor, replaceColor);
        const canvas = fabricCanvasRef.current;
        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        restoreCanvasZoom();
        const worker = new Worker('colorSwapWorker.js'); // Path to your Web Worker script
    
        worker.postMessage({ 
            imageData, 
            targetColor, 
            replaceColor, 
            tolerance, 
            startIndex: 0  // Initial start index
        });

        disableHistory.current = true;
    
        worker.onmessage = (e) => {
            if (e.data.finished) {
                console.log("DONE!!!")
                let canvas = fabricCanvasRef.current;
                let image = fabricImageRef.current;

                worker.terminate();
                context.putImageData(e.data.imageData, 0, 0);
                let dataUrl = context.canvas.toDataURL();
                disableHistory.current = false;
                setCanvasImg(dataUrl);

            } else {
                // Continue processing by sending back the necessary data and where to continue
                worker.postMessage({ 
                    imageData: e.data.imageData, 
                    targetColor, 
                    replaceColor, 
                    tolerance, 
                    startIndex: e.data.startIndex, // Updated start index
                    continueProcessing: true 
                });
            }
        };
    };

    const applyFloodFill = (fabricCanvas, x, y, fillColor) => {
        resetCanvasZoom();
        // Create an off-screen canvas
        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.width = fabricCanvas.width;
        offScreenCanvas.height = fabricCanvas.height;
        const context = offScreenCanvas.getContext('2d');
    
        // Copy current Fabric canvas to the off-screen canvas
        context.drawImage(fabricCanvas.getElement(), 0, 0);
    
        // Perform flood fill on the off-screen canvas
        floodFill(offScreenCanvas, x, y, fillColor, 50);
    
        // Convert the off-screen canvas to a data URL
        const dataURL = offScreenCanvas.toDataURL();
    
        // Create a Fabric image from the data URL and set it as the background image
        setCanvasImg(dataURL);

        restoreCanvasZoom();
        
    };

    function floodFill(canvas, x, y, fillColor, tolerance = 10) {
        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        const targetColor = getColorAtPixel(canvas, data, x, y);
    
        let stack = [[x, y]];
        let visited = new Set(); // Set to keep track of visited pixels
    
        while (stack.length > 0) {
            let [x, y] = stack.pop();
            let currentPos = (y * canvas.width + x) * 4;
    
            if (visited.has(currentPos)) {
                continue; // Skip already visited positions
            }
    
            while (y-- >= 0 && matchStartColor(data, currentPos, targetColor, tolerance)) {
                currentPos -= canvas.width * 4;
            }
            currentPos += canvas.width * 4;
            ++y;
            let reachLeft = false;
            let reachRight = false;
    
            while (y++ < canvas.height - 1 && matchStartColor(data, currentPos, targetColor, tolerance)) {
                setColorAtPixel(data, currentPos, fillColor);
    
                if (x > 0) {
                    if (matchStartColor(data, currentPos - 4, targetColor, tolerance)) {
                        if (!reachLeft) {
                            stack.push([x - 1, y]);
                            reachLeft = true;
                        }
                    } else if (reachLeft) {
                        reachLeft = false;
                    }
                }
    
                if (x < canvas.width - 1) {
                    if (matchStartColor(data, currentPos + 4, targetColor, tolerance)) {
                        if (!reachRight) {
                            stack.push([x + 1, y]);
                            reachRight = true;
                        }
                    } else if (reachRight) {
                        reachRight = false;
                    }
                }
    
                visited.add(currentPos); // Mark the position as visited
                currentPos += canvas.width * 4;
            }
        }
    
        context.putImageData(imageData, 0, 0);
    }
    
    
    function getColorAtPixel(canvas, data, x, y) {
        let red = data[((canvas.width * y) + x) * 4];
        let green = data[((canvas.width * y) + x) * 4 + 1];
        let blue = data[((canvas.width * y) + x) * 4 + 2];
        let alpha = data[((canvas.width * y) + x) * 4 + 3];
        return [red, green, blue, alpha];
    }
    
    function matchStartColor(data, pixelPos, targetColor, tolerance = 10) {
        // Tolerance is the maximum difference in any color component to be considered a match
        return (
            Math.abs(data[pixelPos] - targetColor[0]) <= tolerance &&
            Math.abs(data[pixelPos + 1] - targetColor[1]) <= tolerance &&
            Math.abs(data[pixelPos + 2] - targetColor[2]) <= tolerance
        );
    }
    
    function setColorAtPixel(data, pixelPos, color) {
        data[pixelPos] = color[0];
        data[pixelPos + 1] = color[1];
        data[pixelPos + 2] = color[2];
        data[pixelPos + 3] = color[3];
    }

    function setMode(mode){
        if(fabricCanvasRef.current!=null){
            fabricCanvasRef.current.isDrawingMode = false;
            switch (mode){
                default:
                case 'draw':
                    fabricCanvasRef.current.isDrawingMode = true;
                    setCurrMode('draw');
                    break;
                case 'fill':
                    setCurrMode('fill');
                    break;
                case 'swap':
                    setCurrMode('swap');
                    break;
                case 'pipette':
                    setCurrMode('pipette');
                    break;
            }
        }
        
            
    }
    
    const precheckWarning = (
        <div className='precheck-warning'>
            <h2>{precheck!==null ? precheck.message : ""}</h2>
        </div>
    )

    
    var hideIfPrecheckFailed = precheck !== null && precheck.pass === false ? {display: "none"} : {};

    const auth = getAuth();
    const navigate = useNavigate();

    const {login} = useLSAuth();
    auth.onAuthStateChanged((userAuth)=>{
        if(userAuth == null) {
            console.log("auth:: User not signed in - optional")
        }
        else if(props.ignoreAuthChanges){
            console.log("Page ignoring auth changes")
            return;
        }
        else{
            console.log("auth:: Page detected auth change",userAuth)
            login(userAuth);
        }
    });

    return (
        <div style={{height: "100vh", overflow: "hidden"}}>
            <Nav ref={headerRef}/>
            <div className="split-screen" style={hideIfPrecheckFailed}>
                <div className="left-pane" ref={canvasPaneRef}>
                    <canvas ref={canvasRef} className='editor-canvas' />
                </div>
                <div className="right-pane">
                    {pngString == null ? "loading" : <ThreePreview pngString={pngString} emulateCanvasClick={emulateCanvasClick} drawMode={currMode}/>}
                </div>
            </div>
            <div className="editor-bar-container" style={hideIfPrecheckFailed}>
                <EditorBar initSave={()=>{setShowSaveModal(true);}} upload={upload} initDownload={initDownload} undoAllowed={undoAllowed} undo={undo} redo={redo} redoAllowed={redoAllowed} brushSize={brushSize} setBrushSize={setBrushSize} selectedColor={selectedColor} setSelectedColor={setSelectedColor} setMode={setMode} mode={currMode}/>
            </div>
            {precheck !== null && precheck.pass === false ? precheckWarning : ""}
            <EditorSaveModal close={()=>{setShowSaveModal(false)}} show={showSaveModal} file={pngString}/>
        </div>
    );
});

export default EditorCanvas;
