export const colors = {
    theme: [
        "#00FF38",
        "#00C2FF",
        "#FF94E1",
        "#282C34",
        "#181818"
    ],
    descript: {
        offWhite: "#F0F0F0",
        alert: "#ef5350",
        command: "#FFB156"
    },
    buttons: {
        default: "#F0F0F0",
        signup: "#00C2FF",
        login: "#00C2FF"
    }
}