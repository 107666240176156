import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { colors } from '../utils/colors';
import { UserPlus } from 'lucide-react';
export default function FAQAccordion(props){
    const classes = {
        accordion: {
            backgroundColor: colors.theme[3], 
            color: "white"
        },
        icon: {
            color: "white"
        },
        summary:{
            fontSize: "16pt",
            fontWeight: "700",
        },
        details:{
            fontSize: "14pt"
        }
    }
    return (
        <div style={{width: "50%"}}>
            <Accordion style={classes.accordion}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon style={classes.icon}/>}
                aria-controls="panel1-content"
                id="panel1-header"
                style={classes.summary}
                >
                Do my friends need an account?
                </AccordionSummary>
                <AccordionDetails style={classes.details}>
                Your friends only need an account if they want to add or remove skins from the party, or use other parts of the LethalSkins platform.<br/><br/>If your friends just want to download the skins from the party, they can just follow Step 3 above.
                </AccordionDetails>
            </Accordion>
            <Accordion style={classes.accordion}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon style={classes.icon}/>}
                aria-controls="panel1-content"
                id="panel1-header"
                style={classes.summary}
                >
                Can my friends add skins to the party?
                </AccordionSummary>
                <AccordionDetails style={classes.details}>
                Yes, add your friends to your party by sharing the code under "Party" in your account Dashboard (click the <UserPlus/> icon). Any party member can add and remove skins.
                </AccordionDetails>
            </Accordion>
            <Accordion style={classes.accordion}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon style={classes.icon}/>}
                aria-controls="panel1-content"
                id="panel1-header"
                style={classes.summary}
                >
                Can I reload / download skins while in-game?
                </AccordionSummary>
                <AccordionDetails style={classes.details}>
                After you complete the one time setup in Step 3 above, skins in your party will auto-sync when you launch the game.<br/><br/>We are working on an improvement to hot-reload skins while in-game, preventing the need for the game to be restarted after setup - stay tuned.
                </AccordionDetails>
            </Accordion>
        </div>
    )
}