import { useState } from "react";
import {storage} from "../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ActionButton from "./ActionButton";
import { UploadFile } from "@mui/icons-material";
import { Modal, Box, Typography, FormGroup, FormControlLabel, Switch, Alert, IconButton } from "@mui/material"

import { colors } from "../utils/colors";
import { FormInput } from "./FormInput";
import { FileUp } from "lucide-react";
import { useLSAuth } from "../providers/AuthContext";
import { ApiUtil } from "../utils/apiUtil";
// TODO: update cloud storage rules: https://firebase.google.com/docs/storage/security#data_validation
// TODO: make this write to /skins/ and update rules to allow small image write that is not overwrite
// TODO: commit everything (including plugin!!1!) and cleanup the different firebase dirs to share one firebase config in this repo
export default function SkinUploader(props){
    const [uploadStatus, setUploadStatus] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [guidelinesAgree, setGuidelinesAgree] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const {authToken} = useLSAuth();
    const api = new ApiUtil(authToken);
    const classes = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: colors.theme[3],
            color: "white",
            border: '2px solid '+colors.theme[3],
            boxShadow: 24,
            padding: "1em 1.5em",
            borderRadius: 4
          }
    }

    const closeModal = () => {
        setShowUploadModal(false);
        setGuidelinesAgree(false);
        setIsPublic(false);
        setModalError(null);
        setUploadLoading(false);
        setFile(null);
        document.getElementById('file-input').value = "";
    }

    const handleClick = () => {
        document.getElementById('file-input').click();
    }

    const handleFileChange = (event) => {
        console.log(event.target.files[0]);

        setShowUploadModal(true);

        setFile(event.target.files[0]);

    };

    const handleUpload = async () => {
        const skinName = document.getElementById('skin-name').value;
        if(!guidelinesAgree){
            setModalError("All uploads must comply with the Community Guidelines");
            return;
        }
        if(skinName === "" || skinName === null){
            setModalError("Please set a descriptive name for your skin");
            return;
        }
        if (!file) {
            setModalError('Please select a file to upload');
            return;
        }
        setUploadLoading(true);
        console.log("uploading")
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', skinName);
        formData.append('partyCode', props.party);
        formData.append('public', isPublic);

        try {
            const response = await api.uploadSkin(formData);

            if (!response.ok) {
                console.log(response)
                throw new Error('Network response was not ok');
            }

            const result = await response.text();

            setUploadStatus(response.data);
            closeModal();
        } catch (error) {
            console.error('Error uploading file:', error);
            setModalError('Error uploading file. Ensure it is a PNG file under 5MB');
            setUploadLoading(false);
        }
    };

    return (
        <>
            {/* <ActionButton title="Upload" icon={UploadFile} onClick={handleClick}/> */}
            <IconButton onClick={handleClick}>
                <FileUp color="white"/>
            </IconButton>
            <input type="file" onChange={handleFileChange} id="file-input" style={{display: "none"}} accept=".png"/>
            <Modal
                open={showUploadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={classes.box}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Finalize Skin Upload
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {modalError != null ? <Alert severity="error" style={{marginBottom: "1em"}}>{modalError}</Alert> : ""}

                    <FormGroup>
                        <FormInput placeholder="Name your skin" id="skin-name" maxLength={32}/>
                        <FormControlLabel id="upload-guidelines-agree" onChange={(e)=>{setGuidelinesAgree(e.target.checked)}} style={{marginBottom: "1em"}} required control={<Switch />} label="This file complies with the Community Guidelines" />
                        <FormControlLabel id="upload-public" onChange={(e)=>{setIsPublic(e.target.checked)}} style={{marginBottom: "1em"}} control={<Switch />} label="I am the original creator of this file and agree to share it on LethalSkins Discover (optional)" />
                        <div style={{display: "flex", justifyContent: "flex-end", gap: 10}}>
                            <ActionButton title="Cancel" onClick={closeModal}/>
                            <ActionButton title="Upload" color={colors.theme[1]} onClick={handleUpload} loading={uploadLoading}/>
                        </div>
                    </FormGroup>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}