import { Box, CircularProgress, Modal, Skeleton, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { skinPreviewModalState } from "../recoil/recoilState";
import { colors } from "../utils/colors";
import ThreePreview from "../components/ThreePreview";
import { useEffect, useState } from "react";
import { update } from "firebase/database";
import { Buffer } from "buffer";
import axios from "axios";
import moment from "moment";
import { X } from "lucide-react";
import { cursors } from "../utils/cursors";

export default function SkinPreviewModal (props) {
    const [skinPreviewModal, setSkinPreviewModal] = useRecoilState(skinPreviewModalState);
    const [pngString, setPngString] = useState(null);

    console.log("skinPreviewModal: ",skinPreviewModal)

    const handleClose = () =>{
        setSkinPreviewModal({skin: null, show: false});
        setPngString(null);
    }

    const classes = {
        box: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "50vw",
            backgroundColor: colors.theme[3],
            color: "white",
            border: '2px solid '+colors.theme[3],
            boxShadow: 24,
            padding: "1em 1.5em",
            borderRadius: 4,
            height: "80vh",
            display: "flex",
            flexDirection: "column"
        }
    }

    async function urlToBase64(url) {
        try {
          const response = await axios.get(url, {
            responseType: 'arraybuffer'
          });
          const base64 = Buffer.from(response.data, 'binary').toString('base64');
          return `data:image/png;base64,${base64}`;
        } catch (error) {
          console.error('"Skin preview modal Error fetching or converting the image:', error);
          return null;
        }
      }

    useEffect(()=>{
        async function updatePngString(){
            console.log("Skinpreviewmodal fetching ", skinPreviewModal.skin.url)
            let newPngString = await urlToBase64(skinPreviewModal.skin.url);
            console.log("Skinpreviewmodal",newPngString)
            setPngString(newPngString);
        }
        if(skinPreviewModal.skin != null){updatePngString();}
    }, [skinPreviewModal.skin])
    
    const skin = skinPreviewModal.skin;

    var content = (
        <div style={{display: "flex", flexDirection: "column", width: "100%", flex: 1}}>
            <Skeleton variant="text" style={{fontSize: "40pt"}}/> <X style={{position: "absolute", right: "1em", cursor: "pointer"}} onClick={handleClose}/>

            <Skeleton variant="rectangle"/>
            <Skeleton variant="rectangle" height={"90%"}/>

        </div>
    )
    if(skinPreviewModal.skin != null && pngString != null){
        content = (
            <div style={{display: "flex", flexDirection: "column", width: "100%", flex: 1}}>
                <Typography id="modal-modal-title" variant="h4" component="h2" style={{fontWeight: 600}}>
                    <span style={{color: colors.theme[0]}}>{skin.name}</span> - by {skin.ownerDisplayName}
                    <X style={{position: "absolute", right: "0.5em", cursor: "pointer"}} onClick={handleClose}/>
                </Typography>
                <Typography variant="h6" component="h2">
                    Created {moment(skin.created).fromNow()} via {skin.editor ? "editor" : "file upload"}
                </Typography>
                <div style={{height: "90%"}}>
                {pngString === null ? <CircularProgress/> : <ThreePreview pngString={pngString} emulateCanvasClick={()=>{}} defaultCursor={cursors.rotate3d}/>}
                </div>
            </div>
        )
    }

    return (
        

       
        <Modal
            open={skinPreviewModal.show}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={classes.box}>
                {content}
            </Box>
        </Modal>

    );
}