import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './screens/Home';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import { createTheme, ThemeProvider } from '@mui/material';
import ThreePreview from './components/ThreePreview';
import EditorCanvas from './screens/EditorCanvas';
import Sync from './screens/Sync';
import { Page } from './screens/Page';
import { AuthProvider } from './providers/AuthContext';

import {
  RecoilRoot,
} from 'recoil';
import { Suspense } from 'react';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page screen={Home} preventContainerClass={true} title="Sync LethalCompany Skins with Friends | LethalSkins"/>
    // <Home title="Home | LethalSkins"/>,
  },
  {
    path: "/sync",
    element: <Page screen={Sync} title="Sync | LethalSkins"/>,
  },
  {
    path: "/steamLogin",
    element: <Page ignoreAuthChanges={true} screen={Login} preventContainerClass={true} screenProps={{formType:"processSteamLogin"}} title="Steam Login | LethalSkins"/>
    //<Login formType="processSteamLogin" />
  },
  // {
  //   path: "/login",
  //   element: <Page screen={Login} preventContainerClass={true} screenProps={{formType:"login"}}/>
  //   //<Login formType="login" title="Login | LethalSkins"/>
  // },
  // {
  //   path: "/signup",
  //   element: <Page screen={Login} preventContainerClass={true} screenProps={{formType:"signup"}}/>
  //   //<Login formType="signup" title="Signup | LethalSkins"/>
  // },
  {
    path: "/dashboard",
    element: <Page screen={Dashboard} title="Dashboard | LethalSkins"/> 
    //<Dashboard title="Dashboard | LethalSkins"/>
  },
  {
    path: "/editor",
    element: 
    <Suspense fallback={<div>Loading</div>}>
      <EditorCanvas title="Editor | LethalSkins"/>
    </Suspense>
    // <Page screen={EditorCanvas} preventContainerClass={true} title="Editor | LethalSkins"/>
    // 
    // 
    // 
  },
  // {
  //   path: "/three",
  //   element: <ThreePreview/>
  // }
]);


function App() {

  
  return (
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RecoilRoot>
            <RouterProvider router={router} />
          </RecoilRoot>
        </AuthProvider>
      </ThemeProvider>
  );
}

export default App;
